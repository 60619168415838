import React from 'react';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import { stripe } from 'shared/utils/stripe';

interface StripeWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

const StripeWrapper = ({ children }: StripeWrapperProps): JSX.Element => {
  return <StripeElementsProvider stripe={stripe}>{children}</StripeElementsProvider>;
};

export default StripeWrapper;
