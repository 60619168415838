import { loadStripe } from '@stripe/stripe-js/pure';
import * as Sentry from '@sentry/react';
import { env } from 'env';

/**
 * Try to reload Stripe on failure
 */
const getReloadCount = (): number => {
  const count = localStorage.getItem('page-reload-count');
  return count ? Number(count) : 0;
};

const setReloadCount = (count: number): void => {
  localStorage.setItem('page-reload-count', count.toString());
};

export const stripe = loadStripe(env.REACT_APP_STRIPE_API_KEY || '')
  .then((result) => {
    setReloadCount(0);
    return result;
  })
  .catch((e) => {
    const count = getReloadCount();
    if (count < 2) {
      setReloadCount(count + 1);
      location.reload();
    } else {
      Sentry.captureException(e);
    }
    return null;
  });
