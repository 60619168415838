import React, { useRef, useContext, useMemo } from 'react';
import { useDay } from '@datepicker-react/hooks';
import { DatePickerContext } from '../contexts/DatePickerContext';
import { Button } from '@televet/kibble-ui/build/components/Button';
import isToday from 'date-fns/isToday';

interface IDayProps {
  dayLabel: string;
  date: Date;
  isAvailable: boolean;
  onDaySelected?: () => void;
}

const Day = ({ dayLabel, date, isAvailable, onDaySelected }: IDayProps): JSX.Element => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatePickerContext);

  const { disabledDate, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  const isDisabled = useMemo(() => disabledDate || !isAvailable, [disabledDate, isAvailable]);

  if (!dayLabel) {
    return <></>;
  }

  const onSelectDay = (): void => {
    onClick();

    if (onDaySelected) {
      onDaySelected();
    }
  };

  return (
    <Button
      onClick={onSelectDay}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      size="xs"
      boxSize={6}
      borderRadius="full"
      fontWeight="normal"
      data-testid="day-button"
      variant={isDisabled ? 'ghostNeutral' : 'secondary'}
      {...(isToday(date) && { variant: 'primary' })}
      isDisabled={isDisabled}
    >
      {dayLabel}
    </Button>
  );
};

export default Day;
