import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import PetAvatar from 'shared/components/PetAvatar';
import { ClinicPet } from 'shared/types/graphql';
import { FormSubmissionMap } from '../../PrescriptionRefill/types';
import { capitalizeName, toPossessiveWord } from 'shared/utils';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { Box, Divider, VStack } from '@televet/kibble-ui/build/chakra';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import PrescriptionCheckbox from '../components/PrescriptionCheckbox';
import { useWidgetStore } from 'state/useWidgetStore';

interface ISelectPrescriptionsProps {
  pet: ClinicPet;
  formSubmission: FormSubmissionMap;
  setFormSubmission: Dispatch<SetStateAction<FormSubmissionMap>>;
}

const SelectPrescriptions = ({
  pet,
  formSubmission,
  setFormSubmission,
}: ISelectPrescriptionsProps): JSX.Element | null => {
  const { goToPreviousStep, goToNextStep, selectedRequest } = useWidgetStore((state) => state);
  const { name, species } = pet;
  const formattedPetName = toPossessiveWord(capitalizeName(name));

  // Reordering here for uniqBy to remove oldest issuedAt, matching descriptions
  const uniquePrescriptions = useMemo(() => {
    const ordered = orderBy(pet.prescriptions, ['description', 'issuedAt'], ['asc', 'desc']);
    return uniqBy(ordered, (rx) => rx.description);
  }, [pet.prescriptions]);

  const hasPrescriptions = useMemo(() => !!uniquePrescriptions.length, [uniquePrescriptions]);
  const selectedPrescriptionsIds = useMemo(
    () => formSubmission[pet.id]?.prescriptions?.map((prescription) => prescription.id) || [],
    [formSubmission, pet.id],
  );

  const handleNote = (note: string): void => {
    setFormSubmission((currentFormSubmission: FormSubmissionMap) => {
      const updatedFormSubmission = { ...currentFormSubmission };
      updatedFormSubmission[pet.id].note = note;

      return updatedFormSubmission;
    });
  };

  const handleSubmitButton = useCallback(() => {
    if (!hasPrescriptions || !selectedRequest?.requestFormTemplate) {
      goToPreviousStep();
    } else {
      goToNextStep();
    }
  }, [goToNextStep, goToPreviousStep, hasPrescriptions, selectedRequest?.requestFormTemplate]);

  return (
    <>
      <VStack p={4} w="100%" align="flex-start" spacing={3}>
        <PetAvatar size="lg" petName={name} species={species || 'Other'} photoUrl={pet.photoUrl} />
        {hasPrescriptions && (
          <VStack w="100%">
            <Text>
              Select which of{' '}
              <Text as="span" fontWeight={600}>
                {formattedPetName}
              </Text>{' '}
              prescriptions you&apos;d like to have refilled.
            </Text>
            {uniquePrescriptions.map((prescription) => {
              const isChecked = selectedPrescriptionsIds.includes(prescription.id);
              return (
                <PrescriptionCheckbox
                  key={prescription.id}
                  prescription={prescription}
                  setFormSubmission={setFormSubmission}
                  petId={pet.id}
                  isChecked={isChecked}
                />
              );
            })}
          </VStack>
        )}
      </VStack>
      <Divider />
      <VStack p={4}>
        <Text>
          {hasPrescriptions
            ? `Are we missing ${formattedPetName} medication in this list? Is there a concern with the current prescriptions listed above?`
            : `${name} doesn't appear to have any prescriptions attached to their account. If you have any questions or concerns with this please let us know:`}
        </Text>
        <Textarea
          name="note"
          placeholder="Type something..."
          mt={3}
          defaultValue={formSubmission[pet.id]?.note}
          onChange={(e): void => handleNote(e.currentTarget.value)}
        />
      </VStack>
      <Divider />
      <Box p={4} w="100%">
        <Button isFullWidth onClick={handleSubmitButton}>
          {hasPrescriptions ? 'Next' : 'Done'}
        </Button>
      </Box>
    </>
  );
};

export default SelectPrescriptions;
