export type MembershipStatus = 'hasNoPets' | 'hasNoMemberships' | 'hasNoProfile' | 'isEnrolled' | 'isInactive';
type LinkSendMethod = 'phone number' | 'email';

export interface TeletriageState {
  membershipStatus: MembershipStatus | null;
  linkSendMethod: LinkSendMethod;
  resendRequested: boolean;
  failureSendingLink: boolean;
}

export const defaultTeletriageState: TeletriageState = {
  linkSendMethod: 'phone number',
  resendRequested: false,
  failureSendingLink: false,
  membershipStatus: null,
};
