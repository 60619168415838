import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import PetAvatar from 'shared/components/PetAvatar';
import { ClinicPet } from 'shared/types/graphql';
import { FormSubmissionMap } from '../../PrescriptionRefill/types';
import { Box, HStack, VStack } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { Tooltip } from '@televet/kibble-ui/build/components/Tooltip';
import { useWidgetStore } from 'state/useWidgetStore';

interface ISelectPetCardProps {
  pet: ClinicPet;
  setSelectedPet: (pet: ClinicPet) => void;
  formSubmission: FormSubmissionMap | undefined;
  setFormSubmission: Dispatch<SetStateAction<FormSubmissionMap>>;
}

const SelectPetCard = ({
  pet,
  setSelectedPet,
  formSubmission,
  setFormSubmission,
}: ISelectPetCardProps): JSX.Element | null => {
  const { goToNextStep } = useWidgetStore((state) => state);
  const { name, species, prescriptions } = pet;
  const [showDetails, setShowDetails] = useState(false);
  const hasPrescriptions = !!prescriptions.length || false;
  const petFormRecord = formSubmission && formSubmission[pet.id];
  const selectedPrescriptions = petFormRecord?.prescriptions;

  const handleClick = useCallback(() => {
    setSelectedPet(pet);
    goToNextStep();
  }, [goToNextStep, pet, setSelectedPet]);

  const handleRemovePrescription = (prescriptionId: string): void => {
    setFormSubmission((currentFormSubmission: FormSubmissionMap) => {
      const updatedFormSubmission: FormSubmissionMap = {
        ...currentFormSubmission,
      };
      const petRecord = updatedFormSubmission[pet.id];

      petRecord.prescriptions = petRecord.prescriptions?.filter(
        (currentPrescription) => currentPrescription.id !== prescriptionId,
      );

      return updatedFormSubmission;
    });
  };

  return (
    <Card className="SelectPet__Card" size="sm" w="100%" display="flex" flexDir="column" gap={3}>
      <HStack className="SelectPet__Card-Header" alignItems="center" justifyContent="space-between" w="100%">
        <PetAvatar petName={name} size="lg" species={species || 'Other'} photoUrl={pet.photoUrl} />
        <Text size="sm" variant={hasPrescriptions ? 'default' : 'danger'}>
          {hasPrescriptions ? `(${selectedPrescriptions?.length || 0}) refills selected` : 'No prescriptions available'}
        </Text>
      </HStack>
      {selectedPrescriptions && selectedPrescriptions?.length > 0 ? (
        <VStack className="SelectPet__Card-Details">
          {showDetails &&
            selectedPrescriptions.map((prescription) => {
              const total = prescription?.totalRefills || 0;
              const current = prescription?.currentRefillNumber || 0;

              const refillsRemaining = total > current ? total - current : 0;
              return (
                <HStack key={prescription.id} alignItems="center" justifyContent="space-between" width="100%">
                  <Text
                    isTruncated={true}
                    tooltipLabel={`${prescription.description} (${refillsRemaining} Refills Left)`}
                  >
                    {prescription.description} <Text size="sm">({refillsRemaining} Refills Left)</Text>
                  </Text>
                  <Box>
                    <Tooltip label="Remove prescription">
                      <Button
                        size="xs"
                        iconName="trashcan"
                        onClick={(): void => handleRemovePrescription(prescription.id)}
                        variant="primaryDestructive"
                      />
                    </Tooltip>
                  </Box>
                </HStack>
              );
            })}
          <HStack className="SelectPet__Card-Details" alignItems="center" justifyContent="space-between" width="100%">
            <Button
              variant="secondary"
              onClick={(): void => setShowDetails(!showDetails)}
              iconName={showDetails ? 'chevronDown' : 'chevronUp'}
              iconPosition="right"
              isFullWidth
            >
              {showDetails ? 'Hide' : 'Show'} Details
            </Button>

            <Button onClick={handleClick} isFullWidth>
              Edit Selections
            </Button>
          </HStack>
        </VStack>
      ) : (
        <VStack>
          <Button isFullWidth variant={hasPrescriptions ? 'primary' : 'secondary'} onClick={handleClick}>
            {petFormRecord?.note ? 'Edit Message' : hasPrescriptions ? 'Select Prescriptions' : 'Make an Inquiry'}
          </Button>
        </VStack>
      )}
    </Card>
  );
};

export default SelectPetCard;
