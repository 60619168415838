import { useToast } from '@televet/kibble-ui/build/components/Toast';
import { AuthenticationForm } from 'pages/Authentication';
import { useCallback } from 'react';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import { useWidgetClinicPetParentLookupLazyQuery, WidgetRequestType } from 'shared/types/graphql';
import { useWidgetStore } from 'state/useWidgetStore';
import * as Sentry from '@sentry/react';
import { defaultTeletriageState } from 'state/types/teletriage';
import { defaultAuthState } from 'state/types/authentication';

interface UseAuthResult {
  lookUpPetParent: ({ phoneNumber, emailAddress, petParentId, requestType }: AuthenticationForm) => Promise<void>;
  isLoadingLookup: boolean;
}

const useAuth = (): UseAuthResult => {
  const toast = useToast();
  const {
    clinicId,
    directBooking: { canCreateClientPatient, requestAppointmentRequest },
    authentication,
    goToNextStep,
    setSelectedRequest,
    handleNewUser,
    handleInactiveUser,
    handleAuthenticatedUser,
    handleCreateNewDbUser,
    handleNewUserNotAllowed,
    updateWidgetState,
    handleNoPetsNotAllowed,
    handleEnrolledUser,
    handleUnenrolledUser,
  } = useWidgetStore((state) => state);

  const [getClinicPetParentData, { loading: isLoadingPetParentData }] = useWidgetClinicPetParentLookupLazyQuery({
    fetchPolicy: GraphQLFetchPolicy.CacheAndNetwork,
  });

  const lookUpPetParent = useCallback(
    async ({ phoneNumber, emailAddress, petParentId, requestType }: AuthenticationForm): Promise<void> => {
      const missingData = !phoneNumber && !emailAddress && !petParentId;
      if (isLoadingPetParentData || missingData) return;

      // Reset pet parent and other auth based state to default before lookup
      updateWidgetState({
        clinicPetParent: null,
        teletriage: defaultTeletriageState,
        authentication: defaultAuthState,
      });

      try {
        const { data } = await getClinicPetParentData({
          variables: {
            where: {
              clinicId: clinicId,
              email: emailAddress || undefined,
              phoneNumber: phoneNumber?.replace(/\D/g, '') || undefined,
              petParentId,
            },
            skipPrescriptions: requestType !== WidgetRequestType.AdvancedRequestRxRefill,
            skipEnrollments: requestType !== WidgetRequestType.Teletriage,
          },
        });

        const petParentData = data?.widgetClinicPetParentLookup?.[0];

        updateWidgetState({
          clinicPetParent: petParentData,
          authentication: {
            ...authentication,
            emailAddress,
            phoneNumber,
            hasLookedUpPetParent: true,
          },
        });

        // If pet parent does not exist, map them to request specific new user experiences
        if (!petParentData) {
          switch (requestType) {
            case WidgetRequestType.DirectBooking: {
              // If create client and patient is enabled direct them to create account step
              if (canCreateClientPatient) {
                handleCreateNewDbUser();
                return;
              }

              // If clinic has a request appointment request redirect them to that form as new user
              if (requestAppointmentRequest) {
                setSelectedRequest(requestAppointmentRequest);
                handleNewUser();
                return;
              }

              // Otherwise new users are not supported for direct booking
              handleNewUserNotAllowed();
              return;
            }
            case WidgetRequestType.Teletriage: {
              handleUnenrolledUser('hasNoProfile');
              return;
            }
            case WidgetRequestType.AdvancedRequestRxRefill: {
              handleNewUserNotAllowed();
              return;
            }
            default:
              handleNewUser();
              return;
          }
        }

        // If user is inactive, prevent them from continuing forward in the flow
        if (!petParentData.isActive) {
          switch (requestType) {
            case WidgetRequestType.Teletriage: {
              handleUnenrolledUser('isInactive');
              return;
            }
            default:
              handleInactiveUser();
              return;
          }
        }

        // If user does not have pets, map them to request specific no pet experiences
        if (!petParentData?.pets || !petParentData?.pets.length) {
          switch (requestType) {
            case WidgetRequestType.DirectBooking: {
              // If clinic has a request appointment request redirect them to that form as existing user
              if (requestAppointmentRequest) {
                setSelectedRequest(requestAppointmentRequest);
                goToNextStep();
                return;
              }

              // Otherwise users with no pets are not supported for direct booking
              handleNoPetsNotAllowed();
              return;
            }
            case WidgetRequestType.Teletriage: {
              handleUnenrolledUser('hasNoPets');
              return;
            }
            case WidgetRequestType.AdvancedRequestRxRefill: {
              handleNoPetsNotAllowed();
              return;
            }
          }
        }

        // Teletriage specific authentication
        if (requestType === WidgetRequestType.Teletriage) {
          const hasEnrollment = petParentData?.pets?.some((pet) =>
            pet?.organizationPet?.carePlanEnrollments?.some((enrollment) => enrollment?.status === 'Active'),
          );

          // If user does not have any active enrollments, map them to no membership experience
          if (!hasEnrollment) {
            handleUnenrolledUser('hasNoMemberships');
            return;
          }

          // Otherwise, authenticate user and progress to additional questions step
          handleEnrolledUser();
          return;
        }

        // Otherwise, authenticate user and progress to next step
        handleAuthenticatedUser();
      } catch (e) {
        console.error(e);
        toast({ status: 'error', title: 'Sorry', description: "We're having trouble submitting your request" });
        Sentry.captureException(e);
      }
    },
    [
      isLoadingPetParentData,
      getClinicPetParentData,
      clinicId,
      updateWidgetState,
      authentication,
      handleAuthenticatedUser,
      handleNewUser,
      canCreateClientPatient,
      requestAppointmentRequest,
      handleCreateNewDbUser,
      setSelectedRequest,
      handleNewUserNotAllowed,
      handleUnenrolledUser,
      handleInactiveUser,
      goToNextStep,
      handleNoPetsNotAllowed,
      handleEnrolledUser,
      toast,
    ],
  );

  return {
    lookUpPetParent,
    isLoadingLookup: isLoadingPetParentData,
  };
};

export default useAuth;
