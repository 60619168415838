import React, { useCallback, useMemo } from 'react';
import { WidgetRequestTypeLabels } from '@televet/shared-types/widgetRequest/WidgetRequestTypeLabels';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { useWidgetStore } from 'state/useWidgetStore';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { RequestStep } from 'state/types/steps';

const FormHeader = (): JSX.Element | null => {
  const {
    selectedRequest,
    isPetPortal,
    goToPreviousStep,
    requestSteps,
    currentStepIndex,
    goBackToMainMenu,
  } = useWidgetStore((state) => state);

  const isConfirmationStep = useMemo(() => {
    const confirmationSteps: RequestStep[] = ['BookingConfirmation', 'Confirmation', 'RefillConfirmation'];
    return confirmationSteps.includes(requestSteps[currentStepIndex]);
  }, [currentStepIndex, requestSteps]);

  const handleCloseButtonClick = useCallback(() => {
    goBackToMainMenu();
    window.parent.postMessage(
      {
        event: WindowMessageEvent.CloseWidget,
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, [goBackToMainMenu]);

  return (
    <Flex
      className="Request__Name"
      bgColor="background.secondary"
      py={3}
      px={5}
      w="100%"
      justify="space-between"
      align="center"
    >
      {isPetPortal && !isConfirmationStep && (
        <Button iconName="chevronLeft" size="sm" variant="secondarySubtle" onClick={(): void => goToPreviousStep()} />
      )}
      <Text color="text.secondaryOnContrast" fontWeight="semibold">
        {selectedRequest && (selectedRequest?.displayName || WidgetRequestTypeLabels[selectedRequest?.requestType])}
      </Text>
      {isPetPortal && <Button iconName="close" size="sm" variant="secondarySubtle" onClick={handleCloseButtonClick} />}
    </Flex>
  );
};

export default FormHeader;
