import React, { useEffect, useMemo } from 'react';
import { useGetClinicWidgetRequestQuery } from 'shared/types/graphql';
import { GATrack } from 'shared/hooks/useGA';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { GA4Events } from 'shared/enums/GA4Events';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { useWidgetStore } from 'state/useWidgetStore';

const Confirmation = (): JSX.Element => {
  const { clinicId, selectedRequest, goBackToMainMenu } = useWidgetStore((state) => state);
  const { data: clinicWidgetRequestData, startPolling, stopPolling } = useGetClinicWidgetRequestQuery({
    variables: { where: { id: selectedRequest?.id } },
  });

  const clinicWidgetRequest = useMemo(() => clinicWidgetRequestData?.findUniqueClinicWidgetRequest, [
    clinicWidgetRequestData?.findUniqueClinicWidgetRequest,
  ]);

  const isVerified = useMemo(() => clinicWidgetRequest?.isVerified, [clinicWidgetRequest?.isVerified]);

  useEffect(() => {
    if (!isVerified) {
      setTimeout(() => {
        stopPolling();
      }, 120000);

      startPolling(10000);
    } else {
      stopPolling();
    }
  }, [isVerified, startPolling, stopPolling]);

  useEffect(() => {
    if (!!clinicWidgetRequest?.clinicWidgetRequestType) {
      const viewName = isVerified ? GA4Events.VERIFIED : GA4Events.VERIFIYING;

      GATrack(
        `${WidgetRequestTypeEventPrefix[clinicWidgetRequest.clinicWidgetRequestType.requestType]}_${viewName}_${
          GA4Events.VIEW
        }`,
        {
          clinicId,
        },
      );
    }
  }, [clinicId, clinicWidgetRequest?.clinicWidgetRequestType, isVerified]);

  return (
    <Flex flex="auto" flexDir="column" align="center" justify="center" px={10} textAlign="center" gap={5}>
      {isVerified ? (
        <>
          <Icon name="checkmarkCircle" variant="success" size="2xl" />
          <Heading size="md" data-testid="request-confirmation-header">
            We received your request!
          </Heading>
          <Text>
            Thank you for verifying your contact information. We&apos;ll be in touch shortly regarding your request.
          </Text>
          <Link onClick={goBackToMainMenu}>Go Back to Main Menu</Link>
        </>
      ) : (
        <>
          <Icon name="checkmarkCircle" variant="placeholder" size="2xl" />
          <Heading size="md" data-testid="request-confirmation-header">
            Awaiting verification
          </Heading>
          <Text>Please click the link in your email or text message to verify your contact information.</Text>
        </>
      )}
    </Flex>
  );
};

export default Confirmation;
