import { ClinicWidgetRequestTypeResponse } from 'shared/types';

export interface DirectBookingState {
  canCreateClientPatient: boolean;
  requestAppointmentRequest?: ClinicWidgetRequestTypeResponse;
  isOtcEnabled: boolean;
}

export const defaultDirectBookingState = {
  canCreateClientPatient: false,
  isOtcEnabled: false,
};
