import { Theme, theme as kibbleTheme, extendTheme } from '@televet/kibble-ui/build/theme';
import { getColorContrast } from 'shared/utils/getColorContrast';
import Color from 'color';
import { corePalette } from '@televet/kibble-ui/build/theme/tokens';
import { buttonTheme } from './buttonTheme';

interface ColorPalette {
  primaryColor: string;
  secondaryColor: string;
}

export const neutralTextColors = {
  dark: corePalette.neutral[900],
  light: corePalette.neutral[50],
};

const useWidgetTheme = ({ primaryColor, secondaryColor }: ColorPalette): Theme => {
  const aliasMap = {
    primaryLight: {
      default: primaryColor,
      hover: Color(primaryColor).darken(0.1).toString(),
      pressed: Color(primaryColor).darken(0.2).toString(),
    },
    primaryAlpha: {
      hover: Color(primaryColor).darken(0.1).alpha(0.1).toString(),
      pressed: Color(primaryColor).darken(0.2).alpha(0.1).toString(),
    },
    neutral: neutralTextColors,
    secondary: {
      default: secondaryColor,
      hover: Color(secondaryColor).darken(0.1).toString(),
      pressed: Color(secondaryColor).darken(0.2).toString(),
    },
  };

  const semanticColorTokens = {
    'background.primary': aliasMap.primaryLight.default,
    'background.primary.hover': aliasMap.primaryLight.hover,
    'background.primary.pressed': aliasMap.primaryLight.pressed,
    'background.primaryAlpha.hover': aliasMap.primaryAlpha.hover,
    'background.primaryAlpha.pressed': aliasMap.primaryAlpha.pressed,
    'background.secondary': aliasMap.secondary.default,
    'background.secondary.hover': aliasMap.secondary.hover,
    'text.primary': aliasMap.primaryLight.default,
    'text.primary.hover': aliasMap.primaryLight.hover,
    'text.primary.pressed': aliasMap.primaryLight.pressed,
    'text.accent': aliasMap.secondary.default,
    'text.interactive': aliasMap.primaryLight.default,
    'text.interactive.hover': aliasMap.primaryLight.hover,
    'text.interactive.pressed': aliasMap.primaryLight.pressed,
    'text.primaryOnContrast': getColorContrast(primaryColor),
    'text.secondaryOnContrast': getColorContrast(secondaryColor),
    'text.accent.hover': aliasMap.secondary.hover,
    'border.primary': aliasMap.primaryLight.default,
    'border.focus': aliasMap.primaryLight.default,
    'border.info': aliasMap.primaryLight.default,
  };

  // Disabling this type error because chakra's extendTheme function does not have a return type of Theme
  // eslint-disable-next-line
  // @ts-ignore
  const theme: Theme = extendTheme(kibbleTheme, {
    semanticTokens: {
      colors: {
        ...semanticColorTokens,
      },
    },
    components: {
      Button: buttonTheme,
    },
  });
  return theme;
};

export default useWidgetTheme;
