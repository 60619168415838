import React from 'react';
import RequestTypeList from './RequestTypeList';
import Confirmation from 'pages/Confirmation';
import { ClinicWidgetRequestTypeResponse, ClinicWidgetSettingResponse } from 'shared/types';
import { useWidgetStore } from 'state/useWidgetStore';
import Authentication from 'pages/Authentication';
import RequestForm from 'pages/RequestForm';
import { WidgetRequestType } from 'shared/types/graphql';
import PrescriptionRefill from 'pages/PrescriptionRefill';
import DirectBooking from 'pages/DirectBooking';
import OneTimePassword from 'pages/OneTimePassword';
import RequestTypeGrid from './RequestTypeGrid';
import Teletriage from 'pages/Teletriage';
import { FormContainer } from 'shared/components/FormContainer';

interface WidgetBodyProps {
  requestTypes: ClinicWidgetRequestTypeResponse[] | null;
  widgetSetting: ClinicWidgetSettingResponse;
}

const WidgetBody = ({ requestTypes, widgetSetting }: WidgetBodyProps): JSX.Element | null => {
  const { requestSteps, currentStepIndex, selectedRequest, isPetPortal } = useWidgetStore((state) => state);

  switch (requestSteps[currentStepIndex]) {
    case 'ContactInfo':
      return <Authentication />;
    case 'RequestForm':
      return <RequestForm />;
    case 'OneTimeCode':
      return <OneTimePassword />;
    case 'Confirmation':
      return <Confirmation />;
  }

  if (selectedRequest?.requestType === WidgetRequestType.AdvancedRequestRxRefill) {
    return (
      <FormContainer>
        <PrescriptionRefill />
      </FormContainer>
    );
  }

  if (selectedRequest?.requestType === WidgetRequestType.DirectBooking) {
    return (
      <FormContainer>
        <DirectBooking widgetSetting={widgetSetting} />
      </FormContainer>
    );
  }

  if (selectedRequest?.requestType === WidgetRequestType.Teletriage) {
    return (
      <FormContainer>
        <Teletriage />
      </FormContainer>
    );
  }

  // Maybe in the future we pass in a layout property with value grid or list to give more flexibility to this
  return isPetPortal ? (
    <RequestTypeGrid requestTypes={requestTypes} />
  ) : (
    <RequestTypeList requestTypes={requestTypes} />
  );
};

export default WidgetBody;
