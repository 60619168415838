import * as Sentry from '@sentry/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import { Box, Divider, Flex, useMediaQuery } from '@televet/kibble-ui/build/chakra';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PoweredByStripe from 'shared/components/PoweredByStripe';
import {
  FinancialTransactionType,
  PaymentMedium,
  StripePaymentMethodType,
  useCreateStripePaymentIntentMutation,
  useGetStripePaymentIntentLazyQuery,
} from 'shared/types/graphql';
import { ResponsiveDeviceMaxWidth } from 'shared/utils/ResponsiveDevice/ResponsiveDeviceMaxWidth';
import { email as emailAddressRegex } from 'shared/utils/validation';

export type CheckoutFormProps = {
  petParentEmail: string;
  petParentId: string;
  clinicId: string;
  amount: number;
  onPaymentSuccess: () => void;
  isSubmitButtonDisabled?: boolean;
};

export const CheckoutForm = ({
  petParentEmail,
  amount,
  petParentId,
  clinicId,
  onPaymentSuccess,
  isSubmitButtonDisabled,
}: CheckoutFormProps): JSX.Element => {
  // 375px. We had reports (https://app.clickup.com/t/863gzznhw) from a user with 324px that they couldn't enter card info
  const [isSmallMobileDevice] = useMediaQuery(ResponsiveDeviceMaxWidth.mobileM);
  const stripe = useStripe();
  const elements = useElements();
  const cardInputRef = useRef<HTMLDivElement>(null);

  // UI Validations
  const [isCardValid, setIsCardValid] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [email, setEmail] = useState(petParentEmail);

  // Errors
  const [cardError, setError] = useState<Partial<StripeError> | undefined>();
  const [responseError, setResponseError] = useState<string | undefined>();

  // Payment intent data
  const [stripePaymentId, setStripePaymentId] = useState<string>();
  const [createStripePayment] = useCreateStripePaymentIntentMutation();
  const [getStripePaymentInfo] = useGetStripePaymentIntentLazyQuery();

  const isEmailValid = useMemo(() => emailAddressRegex.test(email), [email]);
  const { error, errorTitle } = useMemo(() => {
    let errorTitle, error;
    if (responseError) {
      errorTitle = 'Something went wrong';
      error = responseError;
    } else if (cardError) {
      errorTitle = 'Please update your card information';
      error = cardError.message;
    }
    return {
      errorTitle,
      error,
    };
  }, [cardError, responseError]);

  const handleCardElementChange = useCallback((event: StripeCardElementChangeEvent) => {
    const { complete, error } = event;
    setResponseError(undefined);
    setError(error); // This will either be a card error or undefined
    setIsCardValid(complete && !error);
  }, []);

  const handlePaymentConfirmationClick = useCallback(async (): Promise<void> => {
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !cardElement) {
      Sentry.captureException("Couldn't connect to Stripe, try again.");
      throw new Error('Could not create payment request. Please try again.');
    }

    setIsPaying(true);
    setResponseError(undefined);
    try {
      // Step 1: Create a payment intent if it doesn't exist
      // Convert the amount to cents for Stripe
      let _stripePaymentId = stripePaymentId;
      if (!stripePaymentId) {
        const { data: createIntentResponse, errors: createError } = await createStripePayment({
          variables: {
            data: {
              amount: amount * 100 /* Convert to cents */,
              paymentMethodType: StripePaymentMethodType.CardPresent,
              clientServiceFeePercent: 0,
            },
            clinicPetParentId: petParentId,
            paymentMedium: PaymentMedium.StripeVirtualTerminal,
            invoiceIds: null,
            emailOverride: email,
            financialTransactionType: FinancialTransactionType.DirectBookingDeposit,
          },
          context: {
            headers: {
              clinic: clinicId,
            },
          },
        });

        if (createError || !createIntentResponse?.createStripePaymentIntent2) {
          Sentry.captureException(createError);
          throw new Error('Could not create payment request. Please try again.');
        }
        _stripePaymentId = createIntentResponse?.createStripePaymentIntent2.id;
        setStripePaymentId(_stripePaymentId);
      }

      // Step 2: Retrieve the payment intent data with the client secret
      const { data: intentDataResponse, error: intentDataError } = await getStripePaymentInfo({
        variables: {
          stripePaymentIntentId: _stripePaymentId || '',
        },
      });

      const intentData = intentDataResponse?.getStripePaymentIntentData;
      if (intentDataError || !intentData) {
        Sentry.captureException(intentDataError);
        throw new Error('Could not retrieve payment request. Please try again.');
      }

      // Step 3: Confirm the payment intent with the card element
      const { paymentIntent: paymentIntentResponse, error: stripePaymentError } = await stripe.confirmCardPayment(
        intentData?.paymentIntentClientSecret || '',
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              email,
            },
          },
        },
      );

      if (stripePaymentError || !paymentIntentResponse) {
        Sentry.captureException(stripePaymentError);
        // According to the Stripe docs: A human-readable message providing more details about the error.
        // For card errors, these messages can be shown to your users.
        throw new Error(
          stripePaymentError?.type === 'card_error'
            ? stripePaymentError?.message
            : 'Could not confirm payment. Please try again.',
        );
      }

      setStripePaymentId(undefined); // Reset the payment ID
      onPaymentSuccess(); // Everything went well, so call the success callback
    } catch (e) {
      setResponseError(e.message);
      Sentry.captureException(e);
    } finally {
      setIsPaying(false);
    }
  }, [
    elements,
    stripe,
    stripePaymentId,
    getStripePaymentInfo,
    email,
    onPaymentSuccess,
    createStripePayment,
    amount,
    petParentId,
    clinicId,
  ]);

  return (
    <Box>
      <Box p={4}>
        <TextInput
          label="Email Address for Receipt *"
          placeholder="e.g. name@domain.com"
          type="email"
          defaultValue={email}
          onChange={(e): void => setEmail(e.target.value)}
          isInvalid={!isEmailValid}
          errorText={'A valid email address is required.'}
        />
      </Box>
      <Box p={4} bgColor="background.subtle">
        <Text as="p" mb={4}>
          Payment
        </Text>
        {!!stripe && !!elements && (
          <Box
            data-testid="stripe-payment-box"
            px={5}
            py={3}
            mb={4}
            border="1px"
            borderColor="border.default"
            borderRadius="lg"
            boxShadow="lg"
          >
            <Box ref={cardInputRef}>
              <CardElement
                onChange={handleCardElementChange}
                options={{
                  style: {
                    base: {
                      color: 'text.default',
                      '::placeholder': {
                        color: 'text.default',
                      },
                      backgroundColor: 'transparent',
                      iconColor: 'text.default',
                      fontSize: isSmallMobileDevice ? '12px' : '16px',
                    },
                  },
                }}
              />
            </Box>
          </Box>
        )}
        <PoweredByStripe themeColor="var(--chakra-colors-text-default)" />
        {error && (
          <Box mt={4}>
            <Alert status="error" hideCloseButton={true} title={errorTitle} description={error} />
          </Box>
        )}
      </Box>
      <Divider borderColor="border.default" />
      <Box p={4}>
        <Flex justifyContent="space-between">
          <Text size="xl" fontWeight="bold">
            Total Due Today:
          </Text>

          <Text size="xl" fontWeight="bold">
            ${amount.toFixed(2)}
          </Text>
        </Flex>
        <Button
          my={4}
          isFullWidth
          type="button"
          isLoading={isSubmitButtonDisabled || isPaying}
          isDisabled={isSubmitButtonDisabled || !isCardValid || !email || !isEmailValid}
          onClick={handlePaymentConfirmationClick}
        >
          Pay & Schedule Appointment
        </Button>
      </Box>
    </Box>
  );
};
