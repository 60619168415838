import React from 'react';
import StripeWrapper from 'shared/components/StripeWrapper';
import { CheckoutForm, CheckoutFormProps } from './CheckoutForm';

export const Checkout = (props: CheckoutFormProps): JSX.Element => {
  return (
    <StripeWrapper>
      <CheckoutForm {...props} />
    </StripeWrapper>
  );
};