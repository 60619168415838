import React from 'react';
import { Species } from 'shared/enums/Species';
import { Avatar, AvatarSize } from '@televet/kibble-ui/build/components/Avatar';
import { IconName } from '@televet/kibble-ui/build/icons';
import { HStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';

const check = (species: string, ...list: Species[]): boolean => {
  if (list.map((l) => l.toLowerCase()).includes(species.toLowerCase())) {
    return true;
  } else {
    return false;
  }
};

const getSpeciesIcon = (species?: string | null): IconName => {
  if (!species) return 'paw';

  if (check(species, Species.Canine)) {
    return 'animalDog';
  } else if (check(species, Species.Feline)) {
    return 'animalCat';
  } else if (check(species, Species.Avian)) {
    return 'animalBird';
  } else if (check(species, Species.Equine)) {
    return 'animalHorse';
  } else if (check(species, Species.Bovine)) {
    return 'animalCow';
  } else {
    return 'paw';
  }
};

interface IPetAvatarProps {
  species?: string | null;
  size?: AvatarSize;
  className?: string;
  petName?: string;
  hidePetName?: boolean;
  photoUrl?: string | null;
}

// TODO - We have this exact same component in clinic-web, maybe we should add this to kibble at some point
const PetAvatar = ({ species, size, petName, hidePetName, className, photoUrl }: IPetAvatarProps): JSX.Element => {
  const speciesIconName = getSpeciesIcon(species || 'Other');
  return (
    <HStack spacing={hidePetName ? 0 : 2}>
      <Avatar
        hideBorder
        size={size}
        {...(photoUrl && {
          src: photoUrl,
        })}
        iconName={speciesIconName}
        data-testid="pet-avatar"
        color="text.default"
        backgroundColor="background.lessSubtle"
        className={className}
        badgeProps={{
          iconName: 'cancel',
          bg: 'background.dangerSubtle',
          iconVariant: 'danger',
          position: 'topRight',
        }}
      />
      <Heading size="sm">{petName}</Heading>
    </HStack>
  );
};

export default PetAvatar;
