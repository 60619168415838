import React, { useEffect, useMemo, useCallback, useRef, lazy, Suspense } from 'react';
import { KibbleThemeProvider, theme as kibbleTheme } from '@televet/kibble-ui/build/theme';
import { Fade, Box } from '@televet/kibble-ui/build/chakra';
import * as Sentry from '@sentry/react';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import {
  IWindowMessageData,
  ISelectRequestTypePayload,
  ISetIsMobilePayload,
} from 'shared/interfaces/IWindowMessageData';
import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import {
  useGetClinicDirectBookingSettingsQuery,
  useGetClinicWidgetSettingQuery,
  WidgetRequestType,
} from 'shared/types/graphql';
import Launcher from './Launcher';
import WidgetPreview from './WidgetPreview';
import useGA, { GATrack } from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { commitInfo } from 'commitInfo';
import useWidgetTheme from 'theme/useWidgetTheme';
import { useWidgetStore } from 'state/useWidgetStore';
import useIsUrlWhitelisted from 'shared/hooks/useIsUrlWhitelisted';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { getIsClientCreationSupported } from 'shared/utils/getIsClientCreationSupported';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import { InitializeState } from 'state/types/initialize';
import WidgetBody from './WidgetBody';

const Widget = lazy(() => import('./Widget'));
const WIDGET_CONTAINER_HEIGHT = 632;
declare global {
  interface Window {
    parentIFrame: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

export const appInfo = { appVersion: parseInt(commitInfo.commitNumber) / 100, ...commitInfo };

const Application = (): JSX.Element | null => {
  const applicationContainerRef = useRef<HTMLDivElement | null>(null);
  const { setSelectedRequest } = useWidgetStore((state) => state);
  const {
    clinicId,
    parentUrl,
    isInline,
    isMobile,
    isOpen,
    showPreview,
    updateWidgetState,
    onToggle,
    initialize,
    updateDbState,
    goToNextStep,
    isPetPortal,
  } = useWidgetStore((state) => state);
  const { isFeatureEnabled } = useFeatureFlag(clinicId);

  useGA({ clinicId });

  const isDirectBookingEnabled = useMemo(() => isFeatureEnabled(FeatureFlagName.DirectBooking), [isFeatureEnabled]);

  const {
    data: clinicWidgetSettingData,
    loading: isClinicWidgetSettingLoading,
    error: clinicWidgetSettingError,
  } = useGetClinicWidgetSettingQuery({
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    skip: !clinicId,
    onError: (e) => {
      console.error(e);
      Sentry.captureException(e);
    },
    onCompleted: (data) => {
      const clinicData = data.findFirstClinicWidgetSetting?.clinic[0];

      if (clinicData) {
        updateWidgetState({
          clinicName: clinicData.name,
          clinicId: clinicData.id,
          clinicEmail: clinicData.clinicEmail,
          clinicPhone: clinicData.phone,
        });
      }

      let isClientPatientCreationSupported = false;

      if (isFeatureEnabled(FeatureFlagName.DirectBookingCreateClientAndPatient)) {
        isClientPatientCreationSupported = getIsClientCreationSupported(clinicData?.integrations || []);
      }

      const requestAppointmentRequest = data.findFirstClinicWidgetSetting?.clinicWidgetRequestTypes.find(
        (requestType) => requestType.requestType === WidgetRequestType.RequestAppointment,
      );

      updateDbState({
        canCreateClientPatient: isClientPatientCreationSupported,
        requestAppointmentRequest: requestAppointmentRequest,
      });
    },
    variables: {
      where: {
        clinic: {
          some: {
            id: {
              equals: clinicId,
            },
          },
        },
      },
    },
  });

  useGetClinicDirectBookingSettingsQuery({
    variables: {
      data: {
        clinicId,
      },
    },
    onCompleted: (data) => {
      if (!data) return;

      updateDbState({
        isOtcEnabled: data.getClinicDirectBookingSettings?.isOneTimePasswordEnabled || false,
      });
    },
    skip: !clinicId || !isDirectBookingEnabled,
    fetchPolicy: GraphQLFetchPolicy.CacheAndNetwork,
  });

  const isUrlWhitelisted = useIsUrlWhitelisted({
    url: parentUrl,
    whitelistedUrls: clinicWidgetSettingData?.findFirstClinicWidgetSetting?.whiteLabelUrls,
  });

  const widgetSettings = useMemo(() => clinicWidgetSettingData?.findFirstClinicWidgetSetting, [
    clinicWidgetSettingData,
  ]);

  const theme = useWidgetTheme({
    primaryColor:
      widgetSettings?.primaryColor && !isPetPortal ? widgetSettings?.primaryColor : kibbleTheme.colors.primary['500'],
    secondaryColor:
      widgetSettings?.secondaryColor && !isPetPortal
        ? widgetSettings?.secondaryColor
        : kibbleTheme.colors.neutral['900'],
  });

  const resizeIFrame = useCallback((): void => {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((info: { clientWidth: number }) => {
        const rect = applicationContainerRef?.current?.getBoundingClientRect();

        window.parentIFrame.size(
          isOpen && isMobile ? WIDGET_CONTAINER_HEIGHT : rect?.height,
          isOpen && isMobile ? info.clientWidth : rect?.width,
        );
      });
    }
  }, [isOpen, isMobile]);

  const handleMessage = useCallback(
    (e: MessageEvent): void => {
      const data: IWindowMessageData<InitializeState & ISelectRequestTypePayload & ISetIsMobilePayload> = e.data;

      switch (e.data.event) {
        case WindowMessageEvent.InitializeWidget:
          initialize({
            clinicId: data.payload.clinicId,
            parentUrl: data.payload.parentUrl,
            isMobile: data.payload.isMobile,
            isInline: data.payload.isInline,
            isPetPortal: data.payload.isPetPortal,
            isOpen: data.payload.isOpen,
            showPreview: data.payload.showPreview,
            petParentId: data.payload.petParentId,
          });

          break;
        case WindowMessageEvent.OpenWidget:
          onToggle(true);

          break;
        case WindowMessageEvent.CloseWidget:
          onToggle(false);

          break;
        case WindowMessageEvent.ToggleWidget:
          onToggle();

          break;
        case WindowMessageEvent.SelectRequestType:
          if (!data.payload.requestType) return;

          // Since we aren't specifying the actual requset id we are just choosing the first request to match the request type
          const firstMatchingRequest = widgetSettings?.clinicWidgetRequestTypes.find(
            (request: ClinicWidgetRequestTypeResponse) => request?.requestType === data.payload.requestType,
          );

          if (firstMatchingRequest) {
            setSelectedRequest(firstMatchingRequest);
            goToNextStep();
          }

          break;
        case WindowMessageEvent.SetIsMobile:
          if (data.payload.hasOwnProperty('isMobile')) {
            initialize({ isMobile: data.payload.isMobile });
          }
          break;
        default:
          return;
      }
    },
    [initialize, onToggle, widgetSettings?.clinicWidgetRequestTypes, setSelectedRequest, goToNextStep],
  );

  // Post widget ready message event
  useEffect(() => {
    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetReady,
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, []);

  // Listen for new message events
  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      handleMessage(e);
    });

    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  // Track widget initialization
  useEffect(() => {
    if (!widgetSettings || !Object.keys(widgetSettings).length) return;

    const requestTypes = widgetSettings.clinicWidgetRequestTypes?.map(({ displayName, requestType, order }) => ({
      displayName,
      requestType,
      order,
    }));

    GATrack(GA4Events.IMPRESSION, {
      clinicId,
      url: parentUrl,
      requestTypes: requestTypes.map((rt) => rt.requestType).join(', '),
      isInline,
      isPetPortal,
    });

    window.parent.postMessage(
      {
        event: WindowMessageEvent.OnWidgetInitialized,
        payload: { requestTypes },
      },
      '*', // Evaluate whether this target origin is appropriate if we send sensitive data in the future
    );
  }, [widgetSettings, parentUrl, clinicId, isInline, isPetPortal]);

  // Toggle widget open when showPreview and isOpen are true
  useEffect(() => {
    if (showPreview && isOpen) {
      onToggle(true);
    }
  }, [isOpen, onToggle, showPreview]);

  // Resize iframe when show preview changes
  useEffect(() => resizeIFrame(), [showPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  // Resize iframe when show preview changes
  useEffect(() => {
    if (widgetSettings) {
      resizeIFrame();
    }
  }, [widgetSettings, isOpen, isMobile, resizeIFrame]);

  if (isClinicWidgetSettingLoading || clinicWidgetSettingError || !widgetSettings?.isEnabled || !isUrlWhitelisted) {
    return null;
  }

  return (
    <KibbleThemeProvider theme={theme}>
      {isPetPortal ? (
        <WidgetBody requestTypes={widgetSettings.clinicWidgetRequestTypes} widgetSetting={widgetSettings} />
      ) : (
        <Box
          className="Widget"
          display="inline-block"
          ref={applicationContainerRef}
          p={isMobile || isInline ? 0 : 3}
          w={(isMobile || isInline) && isOpen ? '100%' : 'auto'}
          minW="92px"
          maxW={(isMobile || isInline) && isOpen ? '100%' : 'unset'}
          h={isInline ? '100vh' : 'auto'}
          minH="92px"
          maxH={isMobile || isInline ? '100%' : isInline ? '100vh' : 'unset'}
          data-iframe-height
          data-iframe-width
        >
          <KibbleThemeProvider theme={theme}>
            {isOpen && (
              // null fallback because it loads so quickly that an intermediate render looks bad
              <Suspense fallback={null}>
                <Widget widgetSetting={widgetSettings} onResizeIFrame={resizeIFrame} />
              </Suspense>
            )}

            {showPreview && (
              <Fade
                className="WidgetPreview__FadeContainer"
                in={showPreview}
                transition={{ exit: { duration: 0.5 }, enter: { duration: 0.5 } }}
              >
                <WidgetPreview
                  data-iframe-height
                  data-iframe-width
                  widgetSetting={widgetSettings}
                  onCloseClick={(): void => {
                    GATrack(GA4Events.DISMISS_X_BUTTON, { clinicId });
                    onToggle(false);
                  }}
                />
              </Fade>
            )}

            {!(isMobile && isOpen) && !isInline && (
              <Fade className="WidgetPreview__FadeContainer" in={true} transition={{ enter: { duration: 0.5 } }}>
                <Launcher
                  onToggleWidget={(): void => {
                    if (isOpen) {
                      GATrack(GA4Events.DIMISS_V_ICON, { clinicId });
                    } else {
                      GATrack(GA4Events.CHAT_BUBBLE_CLICK, { clinicId, isPreview: showPreview });
                    }
                    onToggle();
                  }}
                />
              </Fade>
            )}
          </KibbleThemeProvider>
        </Box>
      )}
    </KibbleThemeProvider>
  );
};

export default Application;
