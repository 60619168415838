import { useCallback, useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { env } from 'env';
import { appInfo } from 'pages/Application';
import { Clinic, ClinicPetParent } from 'shared/types/graphql';
import { useWidgetStore } from 'state/useWidgetStore';
import * as Sentry from '@sentry/react';

type BasicClinicInfo = Pick<Clinic, 'id' | 'name'>;
type BasicClinicPetParent = Pick<ClinicPetParent, 'id' | 'email' | 'firstName' | 'lastName'> & {
  clinic?: BasicClinicInfo | null;
};

const isDevelopment = env.NODE_ENV === 'development';

const useLogRocket = (): { identifyUser: typeof identifyUser } => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { isPetPortal } = useWidgetStore((state) => state);

  useEffect(() => {
    try {
      if (isInitialized) return;

      // Merge IFrames allows us to have the content of the iframe in log rocket sessions
      LogRocket.init(env.REACT_APP_LOG_ROCKET_APP_ID || '', {
        mergeIframes: true,
        childDomains: ['*'],
        release: `${appInfo.appVersion}`,
      });

      setIsInitialized(true);
    } catch (error) {
      if (!isDevelopment) {
        console.error(error);
        Sentry.captureException(error);
      }
    }
  }, [isInitialized]);

  const identifyUser = useCallback(
    (clinicPetParent: BasicClinicPetParent) => {
      if (!isInitialized) return;

      const name = [clinicPetParent.firstName || '', clinicPetParent.lastName || ''].join(' ').trim();
      const email = clinicPetParent.email || 'No email provided';
      const userData = {
        name,
        email,
        clinicId: clinicPetParent.clinic?.id || '',
        clinicName: clinicPetParent.clinic?.name || '',
        environment: env.REACT_APP_PRODUCTION === 'true' ? 'production' : 'development',
        isPetPortal,
      };

      LogRocket.identify(clinicPetParent.id, userData);
      LogRocket.track('SessionStarted', userData);
    },
    [isInitialized, isPetPortal],
  );

  return { identifyUser };
};

export default useLogRocket;
