import React, { useState, useEffect, useMemo } from 'react';
import { IAppointmentDetails } from 'shared/interfaces/IAppointmentDetails';
import AppointmentDetails from './components/AppointmentDetails';
import BookingConfirmation from './components/BookingConfirmation';
import Calendar from './components/Calendar';
import TimeSlotList from './components/TimeSlots';
import {
  useSyncAvailabilitiesMutation,
  PimsIntegrationCapability,
  ClinicPimsIntegrationType,
} from 'shared/types/graphql';
import * as Sentry from '@sentry/react';
import useLogRocket from 'shared/hooks/useLogRocket';
import { useWidgetStore } from 'state/useWidgetStore';
import { ClinicWidgetSettingResponse } from 'shared/types';
import CreateAccount from './components/CreateAccount';
import { DirectBookingStepList } from 'state/types/steps';

interface IDirectBookingProps {
  widgetSetting: ClinicWidgetSettingResponse;
}

const DirectBooking = ({ widgetSetting }: IDirectBookingProps): JSX.Element | null => {
  const { clinicPetParent, currentStepIndex, requestSteps } = useWidgetStore((state) => state);
  const [appointmentDetails, setAppointmentDetails] = useState<IAppointmentDetails>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const { identifyUser } = useLogRocket();

  useEffect(() => {
    identifyUser({
      firstName: clinicPetParent?.firstName || '',
      lastName: clinicPetParent?.lastName || '',
      id: clinicPetParent?.id || '',
      clinic: {
        id: widgetSetting.clinic[0].id || '',
        name: widgetSetting.clinic[0].name || '',
      },
      email: clinicPetParent?.email || '',
    });
  }, [
    identifyUser,
    clinicPetParent?.email,
    clinicPetParent?.firstName,
    clinicPetParent?.id,
    clinicPetParent?.lastName,
    widgetSetting.clinic,
  ]);

  const [syncAvailabilities] = useSyncAvailabilitiesMutation({
    variables: {
      data: {
        clinicId: widgetSetting.clinic[0].id,
        models: [PimsIntegrationCapability.AppointmentAvailability],
        integrationTypes: [ClinicPimsIntegrationType.Bitwerx],
      },
    },
  });

  const integrations = useMemo(() => widgetSetting?.clinic[0].integrations, [widgetSetting?.clinic]);

  useEffect(() => {
    try {
      if (integrations.length) {
        syncAvailabilities();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [integrations.length, syncAvailabilities]);

  switch (requestSteps[currentStepIndex]) {
    case DirectBookingStepList.CreateAccount:
      return <CreateAccount integrations={integrations} />;

    case DirectBookingStepList.AppointmentDetails:
      return (
        <AppointmentDetails
          setAppointmentDetails={setAppointmentDetails}
          appointmentDetails={appointmentDetails}
          integrations={integrations}
        />
      );
    case DirectBookingStepList.Calendar:
      return (
        <Calendar
          setAppointmentDetails={setAppointmentDetails}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          appointmentDetails={appointmentDetails}
        />
      );
    case DirectBookingStepList.Times:
      return (
        <TimeSlotList
          selectedDate={selectedDate}
          setAppointmentDetails={setAppointmentDetails}
          appointmentDetails={appointmentDetails}
        />
      );
    case DirectBookingStepList.PreConfirmation:
    case DirectBookingStepList.BookingConfirmation:
      return (
        <BookingConfirmation
          setAppointmentDetails={setAppointmentDetails}
          appointmentDetails={appointmentDetails}
          integrations={integrations}
        />
      );
    default:
      return null;
  }
};

export default DirectBooking;
