import {
  ClinicPimsIntegrationType,
  VetDataIntegrationSystem,
  ClinicPimsIntegrationSelectionFragment,
} from 'shared/types/graphql';

export const getIsClientCreationSupported = (integrations: ClinicPimsIntegrationSelectionFragment[]): boolean => {
  // PIMS-less clinics are supported
  if (!integrations.length) {
    return true;
  }
  return integrations.some((integration) => {
    if (integration.type === ClinicPimsIntegrationType.Vetdata && integration.vetdata?.system) {
      // cornerstone and avimark are supported
      if (
        [VetDataIntegrationSystem.Cornerstone, VetDataIntegrationSystem.Avimark].includes(integration.vetdata?.system)
      ) {
        return true;
      }
    }
  });
};
