import jwt from 'jsonwebtoken';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import { ClinicPetParentLookupResponse } from 'shared/types';

interface IAuthToken {
  clinicPetParentId: string;
  data: { clinicPetParent: ClinicPetParentLookupResponse };
  exp: number;
  iat: number;
  scopes: string[];
}

export const setAuthToken = (token: string): void => {
  return sessionStorage.setItem(PersistedStateKeys.CachedToken, token);
};

export const clearLocalStorage = (): void => {
  return sessionStorage.clear();
};

export const decodeAuthToken = (token: string): IAuthToken | undefined => {
  const tokenData = jwt.decode(token);
  if (tokenData && typeof tokenData !== 'string') {
    return tokenData as IAuthToken;
  }
};
