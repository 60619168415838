import React, { useCallback, useMemo } from 'react';
import addMinutes from 'date-fns/addMinutes';
import { IAppointmentDetails } from 'shared/interfaces/IAppointmentDetails';
import { getFormattedDate, getFormattedTime } from 'shared/utils';
import {
  DirectBookingDateAvailability,
  Maybe,
  useGetClinicDirectBookingSettingsQuery,
  useGetDirectBookingAvailabilityTimeSlotsQuery,
} from 'shared/types/graphql';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import { ProdClinicIds } from 'shared/enums/ClinicMap';
import { Flex, Center } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { FeatureFlagName } from 'shared/enums/FeatureFlagName';
import useFeatureFlag from 'shared/hooks/useFeatureFlag';
import { useWidgetStore } from 'state/useWidgetStore';

// availability is returned for each 5 minute slot, convert to ms
const TIME_SLOT_INTERVAL = 5 * 60 * 1000;

interface ITimeSlotListProps {
  selectedDate: Date | null;
  setAppointmentDetails: (details: IAppointmentDetails) => void;
  appointmentDetails: IAppointmentDetails | undefined;
}

interface ITimeSlotProps {
  startTime: Date;
  doctorId: string;
}

const TimeSlotList = ({
  selectedDate,
  setAppointmentDetails,
  appointmentDetails,
}: ITimeSlotListProps): JSX.Element | null => {
  const { clinicId, goToNextStep } = useWidgetStore((state) => state);
  const { isFeatureEnabled } = useFeatureFlag(clinicId);

  const doctorIds = appointmentDetails?.providersForAppointmentType?.map((p) => p.id);
  const clinicEmployeeIds =
    appointmentDetails?.doctorId && doctorIds?.includes(appointmentDetails.doctorId)
      ? [appointmentDetails.doctorId]
      : doctorIds;

  const {
    data: availabilityTimeSlotsData,
    loading: isAvailabilityDataLoading,
  } = useGetDirectBookingAvailabilityTimeSlotsQuery({
    variables: {
      data: {
        clinicId: clinicId,
        appointmentTypeId: appointmentDetails?.appointmentType?.id,
        dates: [
          {
            startDate: selectedDate || new Date(),
            endDate: addDays(selectedDate || new Date(), 1),
          },
        ],
        clinicEmployeeIds,
        petParentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    skip: !clinicId,
    fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    notifyOnNetworkStatusChange: true,
  });

  const { data: clinicDirectBookingSettings } = useGetClinicDirectBookingSettingsQuery({
    variables: {
      data: {
        clinicId,
      },
    },
    fetchPolicy: GraphQLFetchPolicy.CacheFirst,
  });

  const appointmentInterval = useMemo(
    () => clinicDirectBookingSettings?.getClinicDirectBookingSettings?.appointmentIntervalInMinutesDisplay || 15,
    [clinicDirectBookingSettings?.getClinicDirectBookingSettings?.appointmentIntervalInMinutesDisplay],
  );

  const appointmentDuration = useMemo(() => appointmentDetails?.appointmentType?.defaultDurationInMinutes || 30, [
    appointmentDetails,
  ]);

  const delayedStartTime = useMemo(() => {
    return (
      (isFeatureEnabled(FeatureFlagName.DirectBookingOffHourStartTime) &&
        appointmentDetails?.appointmentType?.delayedStartTime) ||
      0
    );
  }, [appointmentDetails?.appointmentType?.delayedStartTime, isFeatureEnabled]);

  const getValueOfOpenTimes = (openTimeSlots: DirectBookingDateAvailability | undefined): number[] => {
    if (!openTimeSlots || !Object.keys(openTimeSlots).length) return [];

    return uniq(openTimeSlots.openTimeSlots).map((dateString) => new Date(dateString).valueOf());
  };

  const availabilityMap = useMemo((): Map<string, Maybe<number[]>> => {
    const map = new Map<string, Maybe<number[]>>();

    if (!availabilityTimeSlotsData?.getDirectBookingOpenTimeSlots2.availableTimeSlots) {
      return map;
    }

    availabilityTimeSlotsData.getDirectBookingOpenTimeSlots2.availableTimeSlots
      .filter((doctor) => doctor?.clinicEmployeeId)
      .forEach((doctor, index) => {
        map.set(doctor?.clinicEmployeeId || index.toString(), getValueOfOpenTimes(doctor?.availability?.[0]));
      });

    return map;
  }, [availabilityTimeSlotsData]);

  const usedTimezone = availabilityTimeSlotsData?.getDirectBookingOpenTimeSlots2?.usedTimezone;

  const availableStartTimes = useMemo((): ITimeSlotProps[] => {
    const availableTimes: ITimeSlotProps[] = [];
    const consecutiveSlotsRequired = (appointmentDuration * 60 * 1000) / TIME_SLOT_INTERVAL - 1;

    // Delayed start time is a digit of 5 minutes, so we need to divide by 5 (same as availability) to get the right index to start with.
    const delayedOffset = delayedStartTime ? delayedStartTime / 5 : 0;

    availabilityMap.forEach((times, doctorId) => {
      if (!times) return;

      for (let i = 0; i < times.length; i++) {
        const time = times[i];
        // Appointments must start on the specifiedinterval (times are in ms).
        let startInterval = appointmentInterval * 60 * 1000; // 15 minutes

        // Shore Haven, Good Shepherd - Broken Arrow, and Harborside override for 30, 30, and 20 minutes respectively.
        if ([ProdClinicIds.ShoreHaven as string, ProdClinicIds.GoodShepherdBrokenArrow as string].includes(clinicId)) {
          startInterval = 30 * 60 * 1000; // 30 minutes
        }

        if (clinicId === ProdClinicIds.Harborside) {
          startInterval = 20 * 60 * 1000; // 20 minutes
        }

        if (time % startInterval !== 0) {
          continue;
        }

        const indexToEvaluate = delayedOffset + i;

        // If the value in position (i + n-1) === appointment length - TIME_SLOT_INTERVAL
        // then we know there are enough consecutive slots open.
        const endingTimeSlot = times[indexToEvaluate + consecutiveSlotsRequired];
        if (
          times[indexToEvaluate] &&
          selectedDate &&
          endingTimeSlot &&
          endingTimeSlot === times[indexToEvaluate] + consecutiveSlotsRequired * TIME_SLOT_INTERVAL
        ) {
          const startTime = new Date(times[indexToEvaluate]);
          if (
            differenceInMinutes(startTime, new Date()) <
              (appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours || 1) * 60 ||
            !isSameDay(selectedDate, startTime)
          ) {
            continue;
          }

          availableTimes.push({
            startTime,
            doctorId,
          });
        }
      }
    });

    // Filter out duplicate times with different doctors and sort by start time.
    return uniqBy(availableTimes, (props) => props.startTime.toISOString()).sort((a, b) =>
      new Date(a.startTime).valueOf() > new Date(b.startTime).valueOf() ? 1 : -1,
    );
  }, [
    appointmentDuration,
    delayedStartTime,
    availabilityMap,
    appointmentInterval,
    clinicId,
    selectedDate,
    appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours,
  ]);

  const onTimeSelected = useCallback(
    (props: ITimeSlotProps) => {
      setAppointmentDetails({
        ...appointmentDetails,
        appointmentStartTime: props.startTime,
        doctorId: props.doctorId,
        usedTimezone: usedTimezone,
      });
      goToNextStep();
    },
    [setAppointmentDetails, goToNextStep, appointmentDetails, usedTimezone],
  );

  return (
    <Flex w="100%" flexDir="column" bgColor="background.subtle">
      <Heading size="sm" p={2} bgColor="background.default">
        Select a Time{usedTimezone ? ` (${usedTimezone})` : ''}
      </Heading>
      <Text fontWeight="semibold" textAlign="center" mt={2} size="sm">
        {getFormattedDate(selectedDate || undefined)}
      </Text>

      {isAvailabilityDataLoading ? (
        <Center h="300px">
          <Spinner />
        </Center>
      ) : (
        <>
          {availableStartTimes?.length ? (
            <Flex flexDir="column" p={5} gap={2}>
              {availableStartTimes.map((timeSlotProps) => {
                const formattedStartTime = getFormattedTime(timeSlotProps.startTime);
                const formattedEndTime = getFormattedTime(addMinutes(timeSlotProps.startTime, appointmentDuration));
                const startTimeInMilliseconds = timeSlotProps.startTime.getTime().toString();
                return (
                  <Button
                    key={startTimeInMilliseconds}
                    onClick={(): void => onTimeSelected(timeSlotProps)}
                    isFullWidth={true}
                    variant="secondarySubtle"
                    size="sm"
                    fontWeight="normal"
                    py={4}
                  >{`${formattedStartTime} - ${formattedEndTime}`}</Button>
                );
              })}
            </Flex>
          ) : (
            <Text align="center" mt={4} p={4}>
              Sorry, there are no available times on this day. Please go back and select another date.
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default TimeSlotList;
