import React, { useCallback } from 'react';
import { TeletriageStepList } from 'state/types/steps';
import { useWidgetStore } from 'state/useWidgetStore';
import { LinkSent } from './steps/LinkSent';
import { InvalidMembershipStatus } from './steps/InvalidMembershipStatus';
import { useSendCarePlanSignUpLinkMutation } from 'shared/types/graphql';
import * as Sentry from '@sentry/react';
import { useToast } from '@televet/kibble-ui';

const Teletriage = (): JSX.Element => {
  const toast = useToast();
  const {
    requestSteps,
    currentStepIndex,
    updateTeletriageState,
    clinicPetParent,
    goToNextStep,
    authentication: { phoneNumber },
  } = useWidgetStore((state) => state);

  const [sendClinicPetParentSignUpLink, { loading: isSendingSignUpLink }] = useSendCarePlanSignUpLinkMutation();

  const sendSignUpLink = useCallback(async (): Promise<void> => {
    try {
      const result = await sendClinicPetParentSignUpLink({
        variables: {
          data: {
            clinicPetParentId: clinicPetParent?.id || '',
            phone: phoneNumber?.replace(/\D/g, '') || '',
          },
        },
      });

      updateTeletriageState({ failureSendingLink: !result?.data?.sendCareSignUpEmail?.success });

      if (requestSteps[currentStepIndex] === 'InvalidMembershipStatus') {
        goToNextStep();
      } else if (requestSteps[currentStepIndex] === 'LinkSent') {
        updateTeletriageState({ resendRequested: true });
      }
    } catch (error) {
      toast({ status: 'error', title: 'Error', description: 'Error sending clinic pet parent sign up link' });
      console.error('Error sending clinic pet parent sign up link: ', error);
      Sentry.captureException(error);
      updateTeletriageState({ failureSendingLink: true });
    }
  }, [
    clinicPetParent?.id,
    currentStepIndex,
    goToNextStep,
    phoneNumber,
    requestSteps,
    sendClinicPetParentSignUpLink,
    toast,
    updateTeletriageState,
  ]);

  switch (requestSteps[currentStepIndex]) {
    case TeletriageStepList.InvalidMembershipStatus:
      return <InvalidMembershipStatus sendSignUpLink={sendSignUpLink} isSendingSignUpLink={isSendingSignUpLink} />;
    case TeletriageStepList.LinkSent:
      return <LinkSent sendSignUpLink={sendSignUpLink} />;
    default:
      return <>Sorry, this step is not available!</>;
  }
};

export default Teletriage;
