import { IconName } from '@televet/kibble-ui/build/components/Icon';
import { WidgetRequestType } from 'shared/types/graphql';

const WidgetRequestTypeIconNames: Record<string, IconName> = {
  [WidgetRequestType.RequestAppointment]: 'calendarPen',
  [WidgetRequestType.RequestBoarding]: 'house',
  [WidgetRequestType.RequestGroomingAppoinment]: 'cut',
  [WidgetRequestType.RequestRxRefill]: 'pill',
  [WidgetRequestType.AdvancedRequestRxRefill]: 'pill',
  [WidgetRequestType.TalkToStaff]: 'support',
  [WidgetRequestType.InboundSms]: 'cellphone',
  [WidgetRequestType.RequestMedicalRecords]: 'fileHealth',
  [WidgetRequestType.RequestVirtualConsult]: 'videoCamera',
  [WidgetRequestType.DirectBooking]: 'calendarDate',
  [WidgetRequestType.Teletriage]: 'chatBubble',
};

export const getRequestTypeIconName = (requestType: string): IconName => WidgetRequestTypeIconNames[requestType];
