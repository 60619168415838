import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormQuestionType } from 'shared/enums/FormQuestionType';
import FormRow from 'shared/components/FormRow';
import { GATrack } from 'shared/hooks/useGA';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import isEmpty from 'lodash/isEmpty';
import { GA4Events } from 'shared/enums/GA4Events';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import { Select } from '@televet/kibble-ui/build/components/Select';
import { CheckboxGroup } from '@televet/kibble-ui/build/components/CheckboxGroup';
import { Checkbox } from '@televet/kibble-ui/build/components/Checkbox';
import { DatePicker, DateRange } from '@televet/kibble-ui/build/components/DatePicker';
import { TimePicker } from '@televet/kibble-ui/build/components/TimePicker';
import { Text } from '@televet/kibble-ui/build/components/Text';
import Linkify from 'linkifyjs/react';
import { MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import { useWidgetStore } from 'state/useWidgetStore';
import { HStack } from '@chakra-ui/react';
import { IFormQuestion } from 'shared/interfaces/IFormQuestion';

const FormTemplate = (): JSX.Element => {
  const { selectedRequest, clinicId, clinicPetParent } = useWidgetStore((state) => state);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const track = (inputType: FormQuestionType, answer: any, question: string): void => {
    if (!selectedRequest) return;
    GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest.requestType]}_${GA4Events.QUESTION_INPUT}`, {
      question,
      answer,
      inputType,
      clinicId,
    });
  };

  useEffect(() => {
    if (!isEmpty(errors) && selectedRequest) {
      GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest.requestType]}_${GA4Events.VALIDATION_ERROR}`, { errors });
    }
  }, [errors, selectedRequest]);

  return (
    <>
      {!clinicPetParent && (
        <FormRow>
          <HStack>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: { value: true, message: 'This field is required' },
              }}
              render={({ field, fieldState }): JSX.Element => (
                <TextInput
                  className="Request__TextInput"
                  label="First Name"
                  labelStyle={{ fontWeight: 'semibold' }}
                  isInvalid={fieldState.invalid}
                  onChange={field.onChange}
                  errorText={fieldState.error?.message}
                  isRequired={true}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: { value: true, message: 'This field is required' },
              }}
              render={({ field, fieldState }): JSX.Element => (
                <TextInput
                  className="Request__TextInput"
                  label="Last Name"
                  labelStyle={{ fontWeight: 'semibold' }}
                  isInvalid={fieldState.invalid}
                  onChange={field.onChange}
                  errorText={fieldState.error?.message}
                  isRequired={true}
                  name={field.name}
                />
              )}
            />
          </HStack>
        </FormRow>
      )}
      {selectedRequest?.requestFormTemplate.questions?.map((question: IFormQuestion, index: number) => {
        const { type, prompt, defaultValue, options, isRequired } = question;

        if ([FormQuestionType.RatingInput, FormQuestionType.SignatureInput].includes(type)) {
          return null;
        }

        return (
          <FormRow key={index}>
            <Controller
              control={control}
              name={`${index}-${type}`}
              rules={{
                required: { value: isRequired, message: 'This field is required' },
              }}
              render={({ field, fieldState }): JSX.Element => {
                switch (type) {
                  case FormQuestionType.TextInput:
                    return (
                      <TextInput
                        className="Request__TextInput"
                        label={prompt}
                        labelStyle={{ fontWeight: 'semibold' }}
                        placeholder="Type something..."
                        isInvalid={fieldState.invalid}
                        onChange={field.onChange}
                        errorText={fieldState.error?.message}
                        isRequired={isRequired}
                        onBlur={(e): void => track(type, e?.target?.value, prompt)}
                        name={field.name}
                      />
                    );
                  case FormQuestionType.MultiLineTextInput:
                    return (
                      <Textarea
                        className="Request__TextArea"
                        label={prompt}
                        labelStyle={{ fontWeight: 'semibold' }}
                        placeholder="Type something..."
                        onChange={field.onChange}
                        isInvalid={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        isRequired={isRequired}
                        name={field.name}
                      />
                    );
                  case FormQuestionType.DropDown:
                    return (
                      <Select
                        className="Request__Select"
                        label={prompt}
                        labelStyle={{ fontWeight: 'semibold' }}
                        options={
                          options?.length
                            ? options.map((option) => {
                                return { label: option, value: option };
                              })
                            : []
                        }
                        value={field.value}
                        listProps={{
                          onSelect: (selectedItem: MenuItemProps): void => {
                            const value = selectedItem.value ?? '';
                            field.onChange(value);
                          },
                        }}
                        placeholder="Please select one..."
                        isInvalid={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        onBlur={(e): void => track(type, e?.target?.value, prompt)}
                        isRequired={isRequired}
                        name={field.name}
                      />
                    );
                  case FormQuestionType.MultipleSelection:
                    return (
                      <CheckboxGroup
                        direction="column"
                        label={prompt}
                        labelStyle={{ fontWeight: 'semibold' }}
                        onChange={field.onChange}
                        checkboxes={
                          options?.length
                            ? options.map((option) => {
                                return {
                                  children: option,
                                  value: option,
                                  isInvalid: fieldState.invalid,
                                  useInvalidText: false,
                                  onBlur: (e): void => track(type, e?.target?.value, prompt),
                                  name: field.name,
                                  className: 'Request__Checkbox',
                                };
                              })
                            : []
                        }
                        isInvalid={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        isRequired={isRequired}
                      />
                    );
                  case FormQuestionType.Checkbox:
                    const defaultChecked = typeof defaultValue === 'boolean' ? defaultValue : false;
                    return (
                      <Checkbox
                        className="Request__Checkbox"
                        defaultChecked={defaultChecked}
                        onChange={field.onChange}
                        isInvalid={fieldState.invalid}
                        errorText={fieldState.error?.message}
                        onBlur={(e): void => track(type, e?.target?.value, prompt)}
                        isRequired={isRequired}
                        name={field.name}
                      >
                        {prompt}
                      </Checkbox>
                    );
                  case FormQuestionType.DateInput:
                    return (
                      <DatePicker
                        startDate={null}
                        onDateChange={({ startDate }: DateRange): void => {
                          const dateISO = startDate ? formatISO(startDate, { representation: 'date' }) : '';
                          field.onChange(dateISO);
                        }}
                        inputProps={{
                          label: prompt,
                          labelStyle: { fontWeight: 'semibold' },
                          isInvalid: fieldState.invalid,
                          errorText: fieldState.error?.message,
                          isRequired,
                          isReadOnly: true,
                          onBlur: (e): void => track(type, e?.target?.value, prompt),
                          name: field.name,
                        }}
                      />
                    );
                  case FormQuestionType.TimeInput:
                    return (
                      <TimePicker
                        onTimeChange={(time): void => {
                          const formattedTime = time ? format(time, 'HH:mm') : '';
                          field.onChange(formattedTime);
                        }}
                        inputProps={{
                          label: prompt,
                          labelStyle: { fontWeight: 'semibold' },
                          placeholder: '--:-- --',
                          isInvalid: fieldState.invalid,
                          isRequired,
                          errorText: fieldState.error?.message,
                          onBlur: (e): void => track(type, e?.target?.value, prompt),
                          name: field.name,
                        }}
                      />
                    );
                  case FormQuestionType.TextBlock:
                    return (
                      <Linkify options={{ target: '_blank' }}>
                        {prompt.split('\n').map((line, lineIndex) => {
                          return (
                            <Text
                              className="Request__TextBlock"
                              key={`${index}-${type}-line-${lineIndex}`}
                              as="p"
                              minH={5}
                              sx={{ a: { color: 'text.interactive' } }}
                            >
                              {line}
                            </Text>
                          );
                        })}
                      </Linkify>
                    );
                  case FormQuestionType.RatingInput:
                  default:
                    return <></>;
                }
              }}
            />
          </FormRow>
        );
      })}
    </>
  );
};

export default FormTemplate;
