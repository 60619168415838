import React from 'react';
import { Flex } from '@televet/kibble-ui/build/chakra';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { useWidgetStore } from 'state/useWidgetStore';

export function ClinicPhoneOrEmailLinks(): JSX.Element {
  const { clinicPhone, clinicEmail } = useWidgetStore((state) => state);
  return (
    <Flex mt={5} flexDir="column" gap={2}>
      {clinicPhone && (
        <Link
          href={`tel: ${clinicPhone}`}
          variant="default"
          iconProps={{ name: 'phone' }}
          sx={{ div: { color: 'background.primary' } }}
        >
          {clinicPhone}
        </Link>
      )}

      {clinicEmail && (
        <Link
          href={`mailto: ${clinicEmail}`}
          variant="default"
          iconProps={{ name: 'envelope' }}
          sx={{ div: { color: 'background.primary' } }}
        >
          {clinicEmail}
        </Link>
      )}
    </Flex>
  );
}
