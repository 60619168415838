import { AccessCodeTransferMethod } from 'pages/OneTimePassword';

export interface AuthenticationState {
  hasLookedUpPetParent: boolean;
  hasPerformedWidgetAuthRequest: boolean;
  hasGeneratedAndSentAccessCode: boolean;
  accessCodeTransferMethod: AccessCodeTransferMethod | undefined;
  accessCodeTransferAddress: string;
  isPetParentInactive: boolean;
  emailAddress?: string;
  phoneNumber?: string;
  isNewClient: boolean;
  hasNoPets: boolean;
  showAuthenticationAlert: boolean;
}

export const defaultAuthState: AuthenticationState = {
  hasLookedUpPetParent: false,
  hasPerformedWidgetAuthRequest: false,
  hasGeneratedAndSentAccessCode: false,
  isPetParentInactive: false,
  isNewClient: false,
  accessCodeTransferMethod: undefined,
  accessCodeTransferAddress: '',
  emailAddress: undefined,
  phoneNumber: undefined,
  hasNoPets: false,
  showAuthenticationAlert: false,
};
