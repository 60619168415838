import { ProdClinicIds } from 'shared/enums/ClinicMap';

// TODO: this will come from the Direct Booking Settings page in the future
export const getMaxBookingRangeInMonths = (clinicId: string): number => {
  const caringPathwaysClinicIds: string[] = [
    ProdClinicIds.CaringPathwaysCentral,
    ProdClinicIds.CaringPathwaysEastern,
    ProdClinicIds.CaringPathwaysMountain,
    ProdClinicIds.CaringPathwaysPacific,
  ];

  return caringPathwaysClinicIds.includes(clinicId) ? 1 : 3;
};
