export const buttonTheme = {
  variants: {
    primary: {
      color: 'text.primaryOnContrast',
      svg: {
        color: 'text.primaryOnContrast',
      },
    },
  },
};
