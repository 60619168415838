import React, { useEffect, useRef, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { IRequestForm } from 'shared/interfaces/IRequestForm';
import { GATrack } from 'shared/hooks/useGA';
import { WidgetRequestTypeEventPrefix } from 'shared/enums/WidgetRequestTypeEventPrefix';
import { GA4Events } from 'shared/enums/GA4Events';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex, Box, HStack } from '@televet/kibble-ui/build/chakra';
import { useWidgetStore } from 'state/useWidgetStore';
import FormHeader from '../../shared/components/FormHeader';
import FormTemplate from './FormTemplate';
import {
  ClinicEntityCreationSource,
  useCreateClinicWidgetRequestMutation,
  WidgetRequestType,
} from 'shared/types/graphql';
import * as Sentry from '@sentry/react';
import { IFormTemplate } from 'shared/interfaces/IFormTemplate';
import { FormAnswer } from 'shared/interfaces/IFormQuestion';
import { FormSubHeader } from '../../shared/components/FormSubHeader';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { postRequestSubmittedEvent } from 'shared/utils/requestSubmitted';

const RequestForm = (): JSX.Element | null => {
  const {
    selectedRequest,
    clinicId,
    clinicName,
    parentUrl,
    clinicPetParent,
    authentication,
    isPetPortal,
    goToNextStep,
  } = useWidgetStore((state) => state);
  const formRef = useRef<HTMLDivElement | null>(null);
  const rhfMethods = useForm<IRequestForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = rhfMethods;

  const [
    createClinicWidgetRequest,
    { loading: isCreateClinicWidgetRequestLoading },
  ] = useCreateClinicWidgetRequestMutation();

  const submitHandler = useCallback(async () => {
    if (!selectedRequest) return;

    GATrack(`${WidgetRequestTypeEventPrefix[selectedRequest.requestType]}_${GA4Events.COMPLETE}`, {
      clinicId,
    });

    const { firstName, lastName, ...rest } = getValues();
    const requestFormSubmission = Object.assign({}, selectedRequest?.requestFormTemplate as IFormTemplate);
    if (requestFormSubmission?.questions?.length) {
      // Additional (...rest) form values come from the dynamic template, and each is keyed by
      // its index and type as rendered in the UI. Some "questions" don't have an associated
      // answer, so we must ensure answers are merged by the correct index.
      const answers: FormAnswer[] = [];

      Object.entries(rest).forEach(([key, value]) => {
        const matches = key.match(/^\d+/g);

        if (!matches) {
          return;
        }

        answers[Number(matches[0])] = value;
      });

      requestFormSubmission.questions = requestFormSubmission.questions.map((question, index) => {
        const answer = answers[index];
        if (answer == null) return question;
        return {
          ...question,
          answer,
        };
      });
    }

    const requestPetParent = clinicPetParent
      ? { connect: { id: clinicPetParent.id } }
      : {
          create: {
            email: authentication.emailAddress,
            phoneNumbers: authentication.phoneNumber
              ? { create: [{ number: authentication.phoneNumber.replace(/\D/g, ''), isPrimary: true, isMobile: true }] }
              : undefined,
            firstName: firstName || '',
            lastName: lastName || '',
            clinic: { connect: { id: clinicId } },
            creationSource: ClinicEntityCreationSource.WidgetRequest,
            petParentSetting: {
              create: {},
            },
          },
        };

    try {
      const { data } = await createClinicWidgetRequest({
        variables: {
          data: {
            url: parentUrl,
            clinic: { connect: { id: clinicId } },
            clinicWidgetRequestType: { connect: { id: selectedRequest?.id } },
            clinicPetParent: requestPetParent,
            requestFormSubmission,
          },
        },
      });

      postRequestSubmittedEvent(parentUrl, {
        requestType: selectedRequest?.requestType,
        isNewClient: authentication.isNewClient,
        clinicName: clinicName || '',
        clinicId: clinicId || '',
        channelId: data?.createClinicWidgetRequest?.channelId,
      });

      if (!isPetPortal) {
        goToNextStep();
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }, [
    selectedRequest,
    clinicId,
    getValues,
    clinicPetParent,
    authentication.emailAddress,
    authentication.phoneNumber,
    authentication.isNewClient,
    createClinicWidgetRequest,
    parentUrl,
    clinicName,
    isPetPortal,
    goToNextStep,
  ]);

  // Scroll to first error input
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (!formRef.current) return;
      const firstErrorInput = formRef?.current?.querySelector(`[name="${Object.keys(errors)[0]}"]`);
      if (firstErrorInput) {
        firstErrorInput.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errors]);

  // Scroll to top of form when page loads
  useEffect(() => {
    if (!formRef.current) return;
    formRef?.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <FormProvider {...rhfMethods}>
      <FormHeader />
      <FormSubHeader />

      <Flex
        className="Request__FormContainer"
        flex="auto"
        flexDir="column"
        alignSelf="stretch"
        overflow="auto"
        ref={formRef}
      >
        {selectedRequest?.requestType === WidgetRequestType.Teletriage && (
          <Box p={5}>
            <Alert status="info" hideCloseButton={true} hideIcon={true} mb={5}>
              <HStack>
                <Icon name="starCircle" variant="interactive" size="xl" />
                <Text fontWeight="semibold">Membership Found</Text>
              </HStack>
            </Alert>
            <Text>Please fill out the details below to help our team better assist you.</Text>
          </Box>
        )}
        <form className="Request__Form" onSubmit={handleSubmit(submitHandler)} noValidate={true}>
          <Flex flexDir="column" overflowY="auto">
            <FormTemplate />
          </Flex>

          <Box className="Request__SubmitContainer" p={4} borderTopWidth={1}>
            <Button
              className="Request__SubmitButton"
              isFullWidth={true}
              type="submit"
              data-testid="submit-button"
              isLoading={isCreateClinicWidgetRequestLoading}
            >
              Submit Request
            </Button>
          </Box>
        </form>
      </Flex>
    </FormProvider>
  );
};

export default RequestForm;
