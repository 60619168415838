import React, { useCallback } from 'react';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { VStack } from '@televet/kibble-ui/build/chakra';
import { useWidgetStore } from 'state/useWidgetStore';

import { MembershipStatus } from 'state/types/teletriage';
import { ClinicPhoneOrEmailLinks } from 'shared/components/ClinicPhoneOrEmailLinks';

type MembershipStatusCopy = 'alertTitle' | 'description';

const membershipStatusCopy: Record<MembershipStatus, Record<MembershipStatusCopy, string> | null> = {
  hasNoPets: {
    alertTitle: 'No Pets Found',
    description:
      "We couldn't find a pet associated with your account. Please contact the clinic directly to add a pet to your account.",
  },
  hasNoMemberships: {
    alertTitle: 'No Membership Found',
    description:
      "We couldn't find a membership associated with your account. Use the button below to request a link to sign up for a membership now to start chatting with our 24/7 support staff!",
  },
  hasNoProfile: {
    alertTitle: 'No Profile Found',
    description:
      "We couldn't find your account. Try another phone number or email, or contact the clinic directly to become a client!",
  },
  isInactive: {
    alertTitle: 'No Profile Found',
    description:
      "We couldn't find your account. Try another phone number or email, or contact the clinic directly to become a client!",
  },
  isEnrolled: null,
};

interface InvalidMembershipStatusProps {
  sendSignUpLink: () => Promise<void>;
  isSendingSignUpLink?: boolean;
}

export function InvalidMembershipStatus({
  sendSignUpLink,
  isSendingSignUpLink,
}: InvalidMembershipStatusProps): JSX.Element {
  const {
    authentication: { emailAddress },
    updateTeletriageState,

    goBackToMainMenu,
    teletriage: { membershipStatus },
  } = useWidgetStore((state) => state);

  const handleButtonClick = useCallback(async () => {
    if (membershipStatus === 'hasNoMemberships') {
      updateTeletriageState({ linkSendMethod: emailAddress ? 'email' : 'phone number' });
      await sendSignUpLink();
    } else {
      goBackToMainMenu();
    }
  }, [emailAddress, goBackToMainMenu, membershipStatus, sendSignUpLink, updateTeletriageState]);

  if (!membershipStatus) {
    return <></>;
  }

  return (
    <VStack h="100%" p={4} m={6} spacing={5} align="flex-start" justify="">
      <Alert w="100%" title={membershipStatusCopy[membershipStatus]?.alertTitle} status="warning" hideCloseButton />
      <Text>{membershipStatusCopy[membershipStatus]?.description}</Text>

      {membershipStatus !== 'hasNoMemberships' && <ClinicPhoneOrEmailLinks />}

      <Button
        onClick={handleButtonClick}
        isFullWidth
        {...(membershipStatus === 'hasNoMemberships' && { iconName: 'send', isLoading: isSendingSignUpLink })}
      >
        {membershipStatus !== 'hasNoMemberships' ? 'Back to Options' : 'Send Me a Link'}
      </Button>
    </VStack>
  );
}
