import React, { useCallback } from 'react';
import { WidgetRequestType } from 'shared/types/graphql';
import { WidgetRequestTypeLabels } from '@televet/shared-types/widgetRequest/WidgetRequestTypeLabels';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import { env } from 'env';
import { ProdClinicIds } from 'shared/enums/ClinicMap';
import { Flex, VStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { useWidgetStore } from 'state/useWidgetStore';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import RequestTypeIcon from 'shared/components/RequestTypeIcon';

interface RequestTypeListProps {
  requestTypes?: ClinicWidgetRequestTypeResponse[] | null;
  headingContents?: string | JSX.Element;
}

enum ProfileErrorMessage {
  HasNoPets = "It looks like you don't have any pets associated with your profile. Please select a different option below to get in touch with our team.",
  HasNoProfile = 'This feature is limited to existing clients only. If there is a different phone number or email address associated with your account, you may try again. If you are a new client, please select a different option below to get in touch with our team.',
}

const RequestTypeList = ({ requestTypes, headingContents }: RequestTypeListProps): JSX.Element | null => {
  const {
    clinicId,
    setSelectedRequest,
    goToNextStep,
    authentication: { hasNoPets, showAuthenticationAlert },
  } = useWidgetStore((state) => state);

  const handleRequestTypeClick = useCallback(
    async (request: ClinicWidgetRequestTypeResponse) => {
      setSelectedRequest(request);
      goToNextStep();
    },
    [goToNextStep, setSelectedRequest],
  );

  return (
    <Flex className="RequestList__Container" flex="auto" overflow="auto" flexDir="column">
      <VStack
        className="RequestList__Header"
        align="flex-start"
        bgColor="background.secondary"
        w="100%"
        pt={4}
        px={4}
        pb={10}
      >
        <Heading color="text.secondaryOnContrast">{headingContents || 'Common Requests'}</Heading>
        {showAuthenticationAlert && (
          <Alert
            hideCloseButton
            status="warning"
            title={hasNoPets ? 'No pets found' : 'No profile found'}
            description={hasNoPets ? ProfileErrorMessage.HasNoPets : ProfileErrorMessage.HasNoProfile}
          />
        )}
      </VStack>

      <Flex className="RequestList__List" flexDir="column" align="stretch" mt={-6} px={4} pb={3} gap={3}>
        {requestTypes?.map((request) => {
          if (!request) return <></>;
          const { requestType, id, displayName } = request;
          const label = displayName || WidgetRequestTypeLabels[requestType];

          if (requestType === WidgetRequestType.TalkToStaff) {
            return (
              <Card className="RequestList__ListItem--TalkToStaff" key={id} boxShadow="base">
                {env.NODE_ENV === 'production' && clinicId === ProdClinicIds.Hurricane ? null : (
                  <Flex flexDir="column" mb={4} gap={2}>
                    <Heading size="xs">Need to talk to someone about your pet?</Heading>
                    <Text size="sm">We&apos;re standing by to answer all of your questions.</Text>
                  </Flex>
                )}

                <Button isFullWidth={true} onClick={(): Promise<void> => handleRequestTypeClick(request)}>
                  {label}
                </Button>
              </Card>
            );
          }

          return (
            <Button
              className="RequestList__ListItem"
              key={id}
              onClick={(): Promise<void> => handleRequestTypeClick(request)}
              variant="secondarySubtle"
              boxShadow="base"
              justifyContent="space-between"
              data-testid="request-type-button"
            >
              <Flex gap={2}>
                <RequestTypeIcon requestType={requestType} size="md" />
                <Text size="sm">{label}</Text>
              </Flex>
              <Icon name="chevronRight" size="sm" />
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
};
export default RequestTypeList;
