import React, { Dispatch, SetStateAction } from 'react';
import { Prescription } from 'shared/types/graphql';
import { FormSubmissionMap } from '../../PrescriptionRefill/types';
import { Checkbox } from '@televet/kibble-ui/build/components/Checkbox';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Divider, HStack } from '@televet/kibble-ui/build/chakra';
import formatDate from 'date-fns/format';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Card } from '@televet/kibble-ui/build/components/Card';

interface PrescriptionCheckboxProps {
  prescription: Prescription;
  setFormSubmission: Dispatch<SetStateAction<FormSubmissionMap>>;
  petId: string;
  isChecked: boolean;
}

const PrescriptionCheckbox = ({
  prescription,
  setFormSubmission,
  petId,
  isChecked,
}: PrescriptionCheckboxProps): JSX.Element | null => {
  const total = prescription?.totalRefills || 0;
  const current = prescription?.currentRefillNumber || 0;
  const refillsRemaining = total > current ? total - current : 0;

  const handlePrescriptionClick = (): void => {
    setFormSubmission((currentFormSubmission: FormSubmissionMap) => {
      const updatedFormSubmission = { ...currentFormSubmission };
      const petRecord = updatedFormSubmission[petId];

      if (petRecord.prescriptions?.includes(prescription)) {
        petRecord.prescriptions = petRecord.prescriptions?.filter(
          (currentPrescription) => currentPrescription.id !== prescription.id,
        );
      } else {
        petRecord.prescriptions?.push(prescription);
      }

      return updatedFormSubmission;
    });
  };

  return (
    <Card
      isInteractive
      isActive={isChecked}
      size="sm"
      w="100%"
      display="flex"
      flexDir="column"
      gap={2}
      onClick={handlePrescriptionClick}
    >
      <Checkbox id={prescription.id} isChecked={isChecked} onChange={handlePrescriptionClick}>
        <Text fontWeight={600}>{prescription.description}</Text>
      </Checkbox>
      <Divider />
      <HStack align="center" justifyContent="space-between" width="100%">
        <HStack spacing={1} align="center">
          <Icon size="sm" variant="subtle" name="pill" />
          <Text size="sm" variant="subtle">
            ({refillsRemaining}) Refills left
          </Text>
        </HStack>

        {prescription.lastRequestedAt && (
          <HStack spacing={1} align="center">
            <Icon size="sm" name="calendarCheck" />
            <Text size="sm" fontStyle="italic">
              Last requested {formatDate(new Date(prescription.lastRequestedAt), 'M/d/yy')}
            </Text>
          </HStack>
        )}
      </HStack>
    </Card>
  );
};

export default PrescriptionCheckbox;
