import { IconName } from '@televet/kibble-ui/build/components/Icon';
import { SpeciesType } from 'shared/types/graphql';

export const getSpeciesTypeIcon = (species: string): IconName => {
  switch (species) {
    case SpeciesType.Avian:
      return 'animalBird';
    case SpeciesType.Bovine:
      return 'animalCow';
    case SpeciesType.Canine:
      return 'animalDog';
    case SpeciesType.Feline:
      return 'animalCat';
    case SpeciesType.Equine:
      return 'animalHorse';
    default:
      return 'paw';
  }
};
