import React, { useCallback, useRef } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex, Box, VStack, Divider } from '@televet/kibble-ui/build/chakra';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { useWidgetStore } from 'state/useWidgetStore';
import FormHeader from 'shared/components/FormHeader';
import { email as emailAddressRegex, phone as phoneNumberRegex } from 'shared/utils/validation';
import { Alert } from '@televet/kibble-ui';
import useAuth from 'shared/hooks/useAuth';
import { WidgetRequestType } from 'shared/types/graphql';

export interface AuthenticationForm {
  phoneNumber?: string;
  emailAddress?: string;
  petParentId?: string | null;
  requestType?: WidgetRequestType | null;
}

const Authentication = (): JSX.Element | null => {
  const { lookUpPetParent, isLoadingLookup } = useAuth();
  const {
    selectedRequest,
    authentication: { emailAddress, phoneNumber, isPetParentInactive },
  } = useWidgetStore((state) => state);
  const formRef = useRef<HTMLDivElement | null>(null);
  const rhfMethods = useForm<AuthenticationForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    register,
    trigger,
    getValues,
    formState: { errors },
  } = rhfMethods;

  const onSubmit: SubmitHandler<AuthenticationForm> = useCallback(
    async ({ emailAddress, phoneNumber }): Promise<void> => {
      await lookUpPetParent({ emailAddress, phoneNumber, requestType: selectedRequest?.requestType });
    },
    [lookUpPetParent, selectedRequest?.requestType],
  );

  return (
    <VStack align="flex-start" alignSelf="stretch" spacing={3} h="100%" mb={4} overflow="auto">
      <FormProvider {...rhfMethods}>
        <FormHeader />
        {isPetParentInactive && (
          <Box className="Request__Warning--NoProfile" m={4} px={4}>
            <Alert
              status="warning"
              hideCloseButton={true}
              title="No profile found."
              description="We're unable to load your profile. Please contact your clinic for more information."
            />
          </Box>
        )}
        <VStack
          className="Request__FormContainer"
          flex="auto"
          alignSelf="stretch"
          align="flex-start"
          overflow="auto"
          p={4}
          ref={formRef}
        >
          <Flex flexDir="column" mb={2} gap={1}>
            <Text fontWeight="bold">Enter your contact info</Text>
            <Text>Please provide either your phone number or email address.</Text>
          </Flex>

          <Box w="100%">
            <form onSubmit={handleSubmit(onSubmit)} id="auth-form" noValidate={true}>
              <TextInput
                label="Mobile Phone Number"
                labelStyle={{
                  fontWeight: 'bold',
                }}
                type="tel"
                defaultValue={phoneNumber}
                placeholder="e.g. 123-456-7890"
                isInvalid={!!errors.phoneNumber}
                errorText={errors?.phoneNumber?.message}
                {...register('phoneNumber', {
                  validate: {
                    isProvided: (value): boolean | string => {
                      if (!!value && !getValues('emailAddress')) {
                        trigger('emailAddress');
                      }

                      return !!value || !!getValues('emailAddress') || 'Please provide a valid US phone number';
                    },
                  },
                  pattern: {
                    value: phoneNumberRegex,
                    message: 'Please provide a valid US phone number',
                  },
                })}
              />

              <Flex py={4} align="center">
                <Divider borderColor="border.strong" />
                <Text size="sm" px={5}>
                  or
                </Text>
                <Divider borderColor="border.strong" />
              </Flex>

              <TextInput
                label="Email Address"
                labelStyle={{
                  fontWeight: 'bold',
                }}
                placeholder="e.g. name@domain.com"
                type="email"
                defaultValue={emailAddress}
                isInvalid={!!errors.emailAddress}
                errorText={errors?.emailAddress?.message}
                {...register('emailAddress', {
                  validate: {
                    isProvided: (value): boolean | string => {
                      if (!!value && !getValues('phoneNumber')) {
                        trigger('phoneNumber');
                      }

                      return !!value || !!getValues('phoneNumber') || 'Please provide a valid email address';
                    },
                  },
                  pattern: {
                    value: emailAddressRegex,
                    message: 'Please provide a valid email address',
                  },
                })}
              />
            </form>
          </Box>
        </VStack>
        <Divider />
        <Box px={4} className="AdditionalQuestions__Container" w="100%">
          <Button
            className="Request__SubmitButton"
            isFullWidth={true}
            type="submit"
            form="auth-form"
            data-testid="submit-button"
            isDisabled={!!errors.phoneNumber || !!errors.emailAddress}
            isLoading={isLoadingLookup}
          >
            Next
          </Button>
        </Box>
      </FormProvider>
    </VStack>
  );
};

export default Authentication;
