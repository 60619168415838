import React, { useEffect, useState } from 'react';
import { FormSubmissionMap } from './types';
import { useWidgetStore } from 'state/useWidgetStore';
import SelectPet from './steps/SelectPet';
import SelectPrescriptions from './steps/SelectPrescriptions';
import AdditionalQuestions from './steps/AdditionalQuestions';
import RefillConfirmation from './steps/RefillConfirmation';
import { ClinicPet } from 'shared/types/graphql';
import { AdvancedRxRefillStepList } from 'state/types/steps';

const PrescriptionRefill = (): JSX.Element | null => {
  const { currentStepIndex, requestSteps, clinicPetParent, selectedRequest } = useWidgetStore((state) => state);
  const [formSubmission, setFormSubmission] = useState<FormSubmissionMap>({});
  const [selectedPet, setSelectedPet] = useState<ClinicPet>();
  const [careConversationId, setCareConversationId] = useState('');

  useEffect(() => {
    // Only create the formSubmission object the first time
    if (Object.keys(formSubmission).length === 0 && !!clinicPetParent?.pets.length) {
      let tempFormSubmission: FormSubmissionMap = {};
      clinicPetParent?.pets.map((pet) => {
        const hasPrescriptions = !!pet?.prescriptions?.length;
        const form =
          selectedRequest && selectedRequest.requestFormTemplate && hasPrescriptions
            ? {
                id: selectedRequest.requestFormTemplate.id,
                name: selectedRequest.requestFormTemplate.formName,
                introMessage: selectedRequest.requestFormTemplate.introMessage,
                questions: selectedRequest.requestFormTemplate.questions,
                petId: pet.id,
                isValid: false,
              }
            : undefined;

        const record = {
          [pet.id]: {
            id: pet.id,
            name: pet.name,
            prescriptions: [],
            note: '',
            form,
          },
        };

        tempFormSubmission = { ...tempFormSubmission, ...record };
      });
      setFormSubmission(tempFormSubmission);
    }
  }, [clinicPetParent?.pets, formSubmission, selectedRequest, setFormSubmission]);

  switch (requestSteps[currentStepIndex]) {
    case AdvancedRxRefillStepList.SelectPet:
      return (
        <SelectPet
          setSelectedPet={setSelectedPet}
          formSubmission={formSubmission}
          setFormSubmission={setFormSubmission}
          setCareConversationId={setCareConversationId}
        />
      );
    case AdvancedRxRefillStepList.SelectPrescriptions:
      if (formSubmission && selectedPet) {
        return (
          <SelectPrescriptions
            pet={selectedPet}
            formSubmission={formSubmission}
            setFormSubmission={setFormSubmission}
          />
        );
      }
      return <></>;
    case AdvancedRxRefillStepList.AdditionalQuestions:
      if (formSubmission && selectedPet) {
        return (
          <AdditionalQuestions
            pet={selectedPet}
            formSubmission={formSubmission}
            setFormSubmission={setFormSubmission}
          />
        );
      }
      return <></>;
    case AdvancedRxRefillStepList.RefillConfirmation:
      return <RefillConfirmation careConversationId={careConversationId} />;
    default:
      return <>Sorry this step is not available</>;
  }
};

export default PrescriptionRefill;
