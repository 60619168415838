import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import PetAvatar from 'shared/components/PetAvatar';
import { ClinicPet } from 'shared/types/graphql';
import { FormSubmissionMap } from '../../PrescriptionRefill/types';
import { useForm, FormProvider } from 'react-hook-form';
import { FormAnswer } from 'shared/interfaces/IFormQuestion';
import { Box, Divider, VStack } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import FormTemplate from 'pages/RequestForm/FormTemplate';
import { useWidgetStore } from 'state/useWidgetStore';

interface IAdditionalQuestionsProps {
  pet: ClinicPet;
  formSubmission: FormSubmissionMap;
  setFormSubmission: Dispatch<SetStateAction<FormSubmissionMap>>;
}

const AdditionalQuestions = ({
  pet,
  formSubmission,
  setFormSubmission,
}: IAdditionalQuestionsProps): JSX.Element | null => {
  const { goToStep } = useWidgetStore((state) => state);
  const { name, species } = pet;
  const formRef = useRef<HTMLDivElement | null>(null);
  const petFormSubmissionRecordForm = useMemo(() => formSubmission[pet.id].form, [formSubmission, pet.id]);
  const defaultFormValues = useMemo(() => {
    const values: { [key: string]: FormAnswer | undefined } = {};
    petFormSubmissionRecordForm?.questions.map((question, index) => {
      const { type, answer } = question;
      const formId = `${index}-${type}`;

      values[formId] = answer;
    });

    return values;
  }, [petFormSubmissionRecordForm?.questions]);
  const rhfMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: defaultFormValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = rhfMethods;

  const isAnswerBlank = (answer: FormAnswer): boolean => {
    if (typeof answer === 'object') {
      return answer.length === 0;
    } else if (typeof answer === 'string') {
      return answer === '';
    }

    return answer === undefined;
  };

  const handleFormSubmission = (): void => {
    setFormSubmission((currentFormSubmission: FormSubmissionMap) => {
      const updatedFormSubmission = { ...currentFormSubmission };
      const petRecord = updatedFormSubmission[pet.id];
      const formValues = getValues();

      if (petRecord.form) {
        petRecord.form.questions.map((question, index) => {
          const { type } = question;
          const formId = `${index}-${type}`;

          const answer = formValues[formId] as FormAnswer;
          if (!isAnswerBlank(answer)) {
            question.answer = answer;
          } else {
            delete question.answer;
          }
        });
        petRecord.form.isValid = true;
      }

      return updatedFormSubmission;
    });
    goToStep('SelectPet');
  };

  // Scroll to to first error on validation
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (!formRef.current) return;
      const firstErrorInput = formRef?.current?.querySelector(`[name="${Object.keys(errors)[0]}"]`);
      if (firstErrorInput) {
        firstErrorInput.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errors]);

  // Scroll to top of form when page loads
  useEffect(() => {
    if (!formRef.current) return;
    formRef?.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <VStack align="flex-start" w="100%" spacing={3} pt={4} ref={formRef}>
      <Box px={4} className="AdditionalQuestions__Container" w="100%">
        <PetAvatar size="lg" petName={name} species={species} photoUrl={pet.photoUrl} />
      </Box>
      <FormProvider {...rhfMethods}>
        <VStack
          justify="flex-start"
          align="stretch"
          w="100%"
          as="form"
          id="rx-refill"
          onSubmit={handleSubmit(handleFormSubmission)}
          noValidate={true}
        >
          {petFormSubmissionRecordForm && (
            <>
              <Text px={4}>{petFormSubmissionRecordForm.introMessage}</Text>
              <FormTemplate />
            </>
          )}
        </VStack>
      </FormProvider>
      <Divider />
      <Box px={4} className="AdditionalQuestions__Container" w="100%">
        <Button isFullWidth type="submit" form="rx-refill" isLoading={isSubmitting}>
          Next
        </Button>
      </Box>
    </VStack>
  );
};

export default AdditionalQuestions;
