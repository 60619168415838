import { ApolloClient, InMemoryCache } from '@apollo/client';
import { env } from './env';
import { commitInfo } from 'commitInfo';

export const client = new ApolloClient({
  uri: env.REACT_APP_CORE_API_URI,
  cache: new InMemoryCache(),
  headers: {
    authorization: `Bearer ${env.REACT_APP_API_KEY}`,
  },
  defaultOptions: {
    watchQuery: {
      /**
       * TODO: Evaluate reverting this to default recommended by Apollo Client
       *
       * Apollo Client 3.4 introduced a change to overwrite rather than merge existing data
       * during a refetch.
       *
       * Source: https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#bug-fixes-28
       */
      refetchWritePolicy: 'merge',
    },
  },
  name: 'requests-widget',
  version: commitInfo.commitHash,
});
