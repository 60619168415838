import React, { useEffect, useCallback } from 'react';
import { useDatepicker, START_DATE, OnDatesChangeProps, FocusedInput } from '@datepicker-react/hooks';
import startOfDay from 'date-fns/startOfDay';
import isSameMonth from 'date-fns/isSameMonth';
import { DatePickerContext } from './contexts/DatePickerContext';
import Month from './components/Month';
import { Box, Grid } from '@televet/kibble-ui/build/chakra';

interface ISelectionCalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  dates: Date[] | null;
  currentDay: Date | null;
  minBookingDays: number;
  onDateChange: (startDate: Date | null, endDate: Date | null, focusedInput?: FocusedInput | undefined) => void;
  onMonthChange: (date: Date) => void;
  className?: string;
  exactMinBookingDays: boolean;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  focusedInput?: FocusedInput;
  numberOfMonths?: number;
  loading?: boolean;
}

const SelectionCalendar = ({
  startDate,
  endDate,
  dates,
  currentDay,
  minBookingDays,
  onDateChange,
  onMonthChange,
  exactMinBookingDays,
  minBookingDate,
  maxBookingDate,
  focusedInput,
  numberOfMonths,
  loading,
}: ISelectionCalendarProps): JSX.Element => {
  const onDatesChange = (data: OnDatesChangeProps): void => {
    const { startDate, endDate, focusedInput } = data;

    onDateChange(startDate, endDate, focusedInput);
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isStartDate,
    isEndDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToDate,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate,
    endDate,
    focusedInput: focusedInput || START_DATE,
    onDatesChange,
    numberOfMonths: numberOfMonths || 1,
    minBookingDays,
    exactMinBookingDays,
    minBookingDate,
    maxBookingDate,
  });

  useEffect(() => {
    if (!activeMonths?.length) return;

    onMonthChange(activeMonths[0].date);
  }, [activeMonths, onMonthChange]);

  const goToMonth = useCallback(() => {
    if (startDate && !isSameMonth(startDate, activeMonths[0].date)) {
      goToDate(startDate || startOfDay(Date.now()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(goToMonth, [startDate, goToMonth]);

  return (
    <DatePickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        isStartDate,
        isEndDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <Box
        pos="relative"
        m="25px 50px 20px 50px"
        bgColor="background.default"
        borderRadius="base"
        borderWidth="1px"
        maxW="300px"
      >
        <Box display="inline-block" w="100%">
          <Grid gap="0 64px">
            {activeMonths.map((month) => {
              return (
                <Month
                  key={`${month.year}-${month.month}`}
                  year={month.year}
                  month={month.month}
                  firstDayOfWeek={firstDayOfWeek}
                  dates={dates}
                  currentDay={currentDay}
                  goToPreviousMonths={goToPreviousMonths}
                  goToNextMonths={goToNextMonths}
                  loading={loading}
                  maxBookingDate={maxBookingDate}
                />
              );
            })}
          </Grid>
        </Box>
      </Box>
    </DatePickerContext.Provider>
  );
};

export default SelectionCalendar;
