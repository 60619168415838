import { useMemo, useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import { FeatureFlag, QueryFindManyFeatureFlagArgs, FeatureFlagState, FeatureFlagType } from 'shared/types/graphql';

const GET_CLINIC_FEATURE_FLAGS = gql`
  query getFeatureFlags($where: FeatureFlagWhereInput!) {
    featureFlags: findManyFeatureFlag(where: $where) {
      id
      key
      type
    }
  }
`;

interface IUseFeatureFlagResult {
  isFeatureEnabled: (key: string) => boolean;
  featureFlags: Record<string, FeatureFlag>;
  isFeatureFlagsLoading: boolean;
}

const useFeatureFlag = (clinicId: string): IUseFeatureFlagResult => {
  const { data: featureFlagsData, loading: isFeatureFlagsLoading } = useQuery<
    { featureFlags: FeatureFlag[] },
    QueryFindManyFeatureFlagArgs
  >(GET_CLINIC_FEATURE_FLAGS, {
    skip: !clinicId,
    variables: {
      where: {
        state: { not: { equals: FeatureFlagState.Off } },
        OR: [
          {
            type: { equals: FeatureFlagType.Clinic },
            clinics: { some: { id: { equals: clinicId } } },
          },
          {
            type: { equals: FeatureFlagType.Global },
          },
        ],
      },
    },
  });

  /**
   * Transform to a Record<key, FeatureFlag> for easy access
   */
  const featureFlags = useMemo((): Record<string, FeatureFlag> => {
    const featureFlags: Record<string, FeatureFlag> = {};

    if (!featureFlagsData) return featureFlags;

    for (const featureFlag of featureFlagsData.featureFlags) {
      featureFlags[featureFlag.key] = featureFlag;
    }

    return featureFlags;
  }, [featureFlagsData]);

  /**
   * Check if feature is enabled by key
   */
  const isFeatureEnabled = useCallback(
    (key: string): boolean => {
      return !!featureFlags[key];
    },
    [featureFlags],
  );

  return { isFeatureEnabled, featureFlags, isFeatureFlagsLoading };
};

export default useFeatureFlag;
