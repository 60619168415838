import React from 'react';
import { Flex, Circle } from '@televet/kibble-ui/build/chakra';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { WidgetRequestType } from 'shared/types/graphql';
import { getRequestTypeIconName } from 'shared/utils/getRequestTypeIconName';

type IconSize = 'md' | 'lg' | 'xl';

interface RequestTypeIconProps {
  requestType: WidgetRequestType;
  size?: IconSize;
}

const CircleSizeMap: Record<IconSize, number | string> = {
  md: 2,
  lg: '10px',
  xl: 3,
};

const CircleRightMap: Record<IconSize, number | string> = {
  md: 0,
  lg: -0.5,
  xl: -1,
};

const RequestTypeIcon = ({ requestType, size = 'md' }: RequestTypeIconProps): JSX.Element => {
  const iconName = getRequestTypeIconName(requestType) || '';

  return (
    <Flex pos="relative" boxSize={5} justify="center">
      <Icon name={iconName} size={size} />
      {requestType === WidgetRequestType.Teletriage && (
        <Circle
          bgColor="text.success"
          display="flex"
          size={CircleSizeMap[size]}
          borderWidth={1}
          borderColor="border.strongContrast"
          pos="absolute"
          top={0}
          right={CircleRightMap[size]}
        />
      )}
    </Flex>
  );
};

export default RequestTypeIcon;
