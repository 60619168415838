const AuthenticationStepList = {
  SelectRequest: 'SelectRequest',
  ContactInfo: 'ContactInfo',
  OneTimeCode: 'OneTimeCode',
} as const;

type AuthenticationStep = typeof AuthenticationStepList[keyof typeof AuthenticationStepList];

const authenticationSteps: AuthenticationStep[] = Object.values(AuthenticationStepList);

const DefaultRequestStepList = {
  RequestForm: 'RequestForm',
  Confirmation: 'Confirmation',
} as const;

type DefaultRequestStep = typeof DefaultRequestStepList[keyof typeof DefaultRequestStepList];

const defaultRequestSteps: DefaultRequestStep[] = Object.values(DefaultRequestStepList);

export const DirectBookingStepList = {
  CreateAccount: 'CreateAccount',
  AppointmentDetails: 'AppointmentDetails',
  Calendar: 'Calendar',
  Times: 'Times',
  PreConfirmation: 'PreConfirmation',
  BookingConfirmation: 'BookingConfirmation',
} as const;

type DirectBookingStep = typeof DirectBookingStepList[keyof typeof DirectBookingStepList];

const directBookingSteps: DirectBookingStep[] = Object.values(DirectBookingStepList);

export const AdvancedRxRefillStepList = {
  SelectPet: 'SelectPet',
  SelectPrescriptions: 'SelectPrescriptions',
  AdditionalQuestions: 'AdditionalQuestions',
  RefillConfirmation: 'RefillConfirmation',
} as const;

type AdvancedRxRefillStep = typeof AdvancedRxRefillStepList[keyof typeof AdvancedRxRefillStepList];

const advancedRxRefillSteps: AdvancedRxRefillStep[] = Object.values(AdvancedRxRefillStepList);

export const TeletriageStepList = {
  InvalidMembershipStatus: 'InvalidMembershipStatus',
  LinkSent: 'LinkSent',
  RequestForm: 'RequestForm',
  Confirmation: 'Confirmation',
} as const;

type TeletriageStep = typeof TeletriageStepList[keyof typeof TeletriageStepList];

const teletriageSteps: TeletriageStep[] = Object.values(TeletriageStepList);

export type RequestStep =
  | AuthenticationStep
  | DefaultRequestStep
  | DirectBookingStep
  | AdvancedRxRefillStep
  | TeletriageStep;

export { authenticationSteps, defaultRequestSteps, directBookingSteps, advancedRxRefillSteps, teletriageSteps };
