import { WindowMessageEvent } from 'shared/enums/WindowMessageEvent';
import { WidgetRequestType } from 'shared/types/graphql';

interface RequestSubmittedEventPayload {
  clinicName: string;
  clinicId: string;
  isNewClient: boolean;
  requestType: WidgetRequestType;
  channelId?: string | null;
}

export const postRequestSubmittedEvent = (url: string, payload: RequestSubmittedEventPayload): void => {
  const eventData = {
    event: WindowMessageEvent.RequestSubmitted,
    payload,
  };

  const televetEventData = {
    event: WindowMessageEvent.TelevetRequestSubmitted,
    payload,
  };

  try {
    const json = JSON.stringify(eventData);
    window.parent.window.postMessage(json, url);

    // For clinics still using the televet event
    const televetJson = JSON.stringify(televetEventData);
    window.parent.window.postMessage(televetJson, url);
  } catch (e) {
    window.console && window.console.log(e);
  }
};
