import format from 'date-fns/format';

export const getFormattedTime = (time: Date): string => {
  return format(time, 'p');
};

export const getFormattedDate = (date: Date | undefined): string => {
  if (!date) return '';

  return format(date, 'PPPP');
};

/** Takes a string and returns 'a' or 'an' based on the starting letter of the string.
 * This approach is over-simplified, so it will return the wrong value for words like 'euphemism'.
 * @param object The string to look at
 * @returns
 */
export const getIndefiniteArticle = (object: string): 'a' | 'an' => {
  if (!object) return 'a';
  const vowels = ['a', 'e', 'i', 'o', 'u']; // skipping 'y'
  return vowels.includes(object.toLowerCase().substring(0, 1)) ? 'an' : 'a';
};

export const capitalizeName = (name?: string | null): string => {
  if (!name) return '';
  return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
};

export const toPossessiveWord = (name: string): string => {
  return `${name}${name.charAt(name.length - 1).toLowerCase() === 's' ? "'" : "'s"}`;
};
