import { Box, VStack } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useCallback } from 'react';
import { useWidgetStore } from 'state/useWidgetStore';

interface InvalidMembershipStatusProps {
  sendSignUpLink: () => Promise<void>;
}

export function LinkSent({ sendSignUpLink }: InvalidMembershipStatusProps): JSX.Element {
  const {
    authentication: { emailAddress, phoneNumber },
    teletriage: { failureSendingLink, linkSendMethod, resendRequested },
    handleChangeUser,
  } = useWidgetStore((state) => state);

  const handleResendLink = useCallback(async () => {
    await sendSignUpLink();
  }, [sendSignUpLink]);

  return (
    <VStack p={4} h="100%" m={6} spacing={5} align="flex-start" justify="">
      {failureSendingLink ? (
        <Box py={5}>
          {`We were unable to send a link to this ${linkSendMethod}, please try again or try a different method.`}
        </Box>
      ) : (
        <>
          <Box py={5} w="100%">
            {`We've sent a link to this ${linkSendMethod}:`}
            <Box bgColor="background.subtle" p={4} mt={5}>
              <Text textAlign="center" fontSize="18px">
                {linkSendMethod === 'email' ? emailAddress : phoneNumber}
              </Text>
            </Box>
          </Box>

          <Text as="p" size="sm" mb={5}>
            {`If you don’t receive the link from us to log into your account, be sure to check that the ${linkSendMethod} above is correct.`}
          </Text>
        </>
      )}

      {!resendRequested && (
        <>
          <Button onClick={handleResendLink} iconName="lock" isFullWidth={true}>
            Resend Secure Link
          </Button>
        </>
      )}

      <Button onClick={handleChangeUser} variant="tertiary" isFullWidth={true} mt={5}>
        Try Another Method
      </Button>
    </VStack>
  );
}
