import React, { useCallback } from 'react';
import { WidgetRequestTypeLabels } from '@televet/shared-types/widgetRequest/WidgetRequestTypeLabels';
import { ClinicWidgetRequestTypeResponse } from 'shared/types';
import { Flex, SimpleGrid } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { useWidgetStore } from 'state/useWidgetStore';
import RequestTypeIcon from 'shared/components/RequestTypeIcon';
import useAuth from 'shared/hooks/useAuth';
import { Spinner } from '@televet/kibble-ui';

interface RequestTypeGridProps {
  requestTypes?: ClinicWidgetRequestTypeResponse[] | null;
  headingContents?: string | JSX.Element;
}

/*
 ** _NOTE_
 ** If we end up using this grid component for anything other than the pet portal,
 ** We will need to retool this to not run the lookup query when clicking a request
 */
const RequestTypeGrid = ({ requestTypes }: RequestTypeGridProps): JSX.Element | null => {
  const { lookUpPetParent, isLoadingLookup } = useAuth();
  const { setSelectedRequest, petParentId } = useWidgetStore((state) => state);

  const handleRequestTypeClick = useCallback(
    async (request: ClinicWidgetRequestTypeResponse) => {
      await lookUpPetParent({ petParentId, requestType: request?.requestType }).then(() => setSelectedRequest(request));
    },
    [lookUpPetParent, petParentId, setSelectedRequest],
  );

  if (isLoadingLookup) {
    return <Spinner isFullScreen />;
  }

  return (
    <SimpleGrid p={4} minChildWidth="180px" spacing={4}>
      {requestTypes?.map((request) => {
        if (!request) return;

        const { requestType, id, displayName } = request;
        const label = displayName || WidgetRequestTypeLabels[requestType];

        return (
          <Card
            className="RequestGrid__GridItem"
            key={id}
            boxShadow="base"
            isInteractive={true}
            onClick={(): Promise<void> => handleRequestTypeClick(request)}
            data-testid="request-type-button"
            p={2}
          >
            <Flex flexDir="column" alignItems="center" justifyContent="center" gap={4}>
              <RequestTypeIcon requestType={requestType} size="xl" />
              <Text overflowWrap="anywhere" fontWeight="semibold" textAlign="center">
                {label}
              </Text>
            </Flex>
          </Card>
        );
      })}
    </SimpleGrid>
  );
};
export default RequestTypeGrid;
