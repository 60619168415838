import React, { ReactNode } from 'react';
import { VStack } from '@televet/kibble-ui';
import FormHeader from './FormHeader';
import { FormSubHeader } from './FormSubHeader';

export function FormContainer({ children }: { children: ReactNode }): JSX.Element {
  return (
    <VStack align="flex-start" w="100%" spacing={0} h="100%" mb={4} overflow="auto">
      <FormHeader />
      <FormSubHeader />
      {children}
    </VStack>
  );
}
