import React from 'react';
import { Circle, VStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { useWidgetStore } from 'state/useWidgetStore';

interface IRefillConfirmationProps {
  careConversationId: string;
}

const RefillConfirmation = ({ careConversationId }: IRefillConfirmationProps): JSX.Element | null => {
  const { goBackToMainMenu } = useWidgetStore((state) => state);

  return (
    <VStack align="center" justify="center" py="50px" px="35px" h="100%" textAlign="center">
      <Circle size="51px" bg="background.success">
        <Icon size="xl" variant="success" name="checkmark" />
      </Circle>
      <Heading>Thank You!</Heading>
      <Text>
        <Text as="span" fontWeight="600">
          Your request has been submitted.{' '}
        </Text>
        Please look for an email arriving soon confirming this request.
      </Text>
      <Text size="sm">
        You can also go{' '}
        <Link size="sm" href={`https://pet.otto.vet/conversations/${careConversationId}`} isExternal>
          here
        </Link>{' '}
        to check the status of your request or visit the pet portal at{' '}
        <Link size="sm" href="https://pet.otto.vet/" isExternal>
          pet.otto.vet
        </Link>
      </Text>
      <Link onClick={goBackToMainMenu}>Go Back to Main Menu</Link>
    </VStack>
  );
};

export default RefillConfirmation;
