export enum GA4Events {
  INIT = 'ga4_initialized',
  IMPRESSION = 'impression',
  CHAT_BUBBLE_CLICK = 'chat_bubble_click',
  DISMISS_X_BUTTON = 'dismiss_x_button',
  DIMISS_V_ICON = 'dismiss_v_icon',
  OTTO_CLICK = 'otto_click',
  OTHER_OPTIONS_CLICK = 'other_options_click',
  DIRECT_BOOKING_CLIENT_ID_SUCCESS = 'direct_book_client_id_success',
  DIRECT_BOOKING_CLIENT_ID_FAILURE = 'direct_book_client_id_failure',
  DIRECT_BOOKING_NEW_PROFILE_CREATION = 'direct_book_new_profile_creation',
  DIRECT_BOOKING_DOCTOR_SELECT = 'direct_book_doctor_select',
  DIRECT_BOOKING_SUMMARY_EDIT = 'direct_book_summary_edit',
  DIRECT_BOOKING_APPT_INTAKE_SUCCESS = 'direct_book_appt_intake_success',
  DIRECT_BOOKING_APPT_INTAKE_FAILURE = 'direct_book_appt_intake_failure',
  // The following events get appended to a request type prefix (Ex: rx_refill_start)
  // See WidgetRequestTypeEventPrefix.ts for the prefixes
  START = 'start',
  BACK_CLICK = 'back_click',
  VERIFIED = 'verified',
  VERIFIYING = 'verifying',
  VIEW = 'view',
  NEXT_CLICK = 'next_click',
  COMPLETE = 'complete',
  QUESTION_INPUT = 'question_input',
  VALIDATION_ERROR = 'validation_error',
  RETURN_MENU = 'return_menu',
}
