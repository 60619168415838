import React, { useMemo } from 'react';
import { Box, HStack } from '@televet/kibble-ui/build/chakra';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { useWidgetStore } from 'state/useWidgetStore';
import { capitalizeName } from 'shared/utils';

export function FormSubHeader(): JSX.Element {
  const {
    clinicPetParent,
    handleChangeUser,
    isPetPortal,
    authentication: { isPetParentInactive },
  } = useWidgetStore((state) => state);

  // We should not show the sub header if:
  // - The pet parent doesn't have a first or last name
  // - The pet parent is inactive
  // - The widget is being rendered in the pet portal
  const shouldHideSubheader = useMemo(
    () => !clinicPetParent?.firstName || !clinicPetParent?.lastName || isPetParentInactive || isPetPortal,
    [clinicPetParent?.firstName, clinicPetParent?.lastName, isPetParentInactive, isPetPortal],
  );

  if (shouldHideSubheader) {
    return <></>;
  }

  return (
    <Box className="Request__Subheader" bgColor="background.contrast" px={4} py={2} w="100%">
      <HStack justifyContent="space-between">
        <Box>
          <HStack>
            <span role="img" aria-label="Wave">
              👋
            </span>
            <Text color="text.onContrast">Hello again,</Text>
            <Text color="text.onContrast" fontWeight="bold" isTruncated>
              {capitalizeName(clinicPetParent?.firstName)} {capitalizeName(clinicPetParent?.lastName)}
            </Text>
          </HStack>
        </Box>

        <Link
          onClick={handleChangeUser}
          textColor="text.onContrast"
          sx={{ span: { color: 'text.onContrast' } }}
          _hover={{ span: { color: 'text.onContrast' } }}
        >
          Not you?
        </Link>
      </HStack>
    </Box>
  );
}
