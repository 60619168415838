import React, { useCallback, useEffect, useMemo } from 'react';
import { FieldValues, useForm, Controller, useController } from 'react-hook-form';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import { IAppointmentDetails } from 'shared/interfaces/IAppointmentDetails';
import {
  ClinicPimsIntegrationSelectionFragment,
  useGetDirectBookingAppointmentTypesQuery,
  useGetProviderAppointmentTypeRoomMapQuery,
  VetDataIntegrationSystem,
  DirectBookingAppointmentTypeClientType,
  ClinicEntityCreationSource,
  GetDirectBookingAppointmentTypesQuery,
  Currency,
} from 'shared/types/graphql';
import { ProdClinicIds } from 'shared/enums/ClinicMap';
import { env } from 'env';
import FormRow from 'shared/components/FormRow';
import addHours from 'date-fns/addHours';
import { Textarea } from '@televet/kibble-ui/build/components/Textarea';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { RadioGroup } from '@televet/kibble-ui/build/components/RadioGroup';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Flex, Box, useRadioGroup, FormLabel, VStack } from '@televet/kibble-ui/build/chakra';
import PetRadio from './PetRadio';
import { useWidgetStore } from 'state/useWidgetStore';
import Linkify from 'linkifyjs/react';

type AppointmentTypeFragment = GetDirectBookingAppointmentTypesQuery['getDirectBookingAppointmentTypes'][0];
interface IAppointmentDetailsProps {
  setAppointmentDetails: (details: IAppointmentDetails) => void;
  appointmentDetails: IAppointmentDetails | undefined;
  integrations: ClinicPimsIntegrationSelectionFragment[];
}

interface IAppointmentDetailsFormValues {
  clinicPetId?: string;
  appointmentType?: string;
  reasonForVisit?: string;
  appointmentStartTime?: Date;
  appointmentEndTime?: Date;
  doctorId?: string;
  doctorName?: string;
}

const AppointmentDetails = ({
  setAppointmentDetails,
  appointmentDetails,
  integrations,
}: IAppointmentDetailsProps): JSX.Element | null => {
  const { clinicId, clinicPetParent, goToNextStep } = useWidgetStore((state) => state);
  const oneHourAgo = addHours(new Date(), -1);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isValid },
  } = useForm<IAppointmentDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      clinicPetId: appointmentDetails?.clinicPet?.id,
      appointmentType: appointmentDetails?.appointmentType?.name || undefined,
      reasonForVisit: appointmentDetails?.reasonForVisit || undefined,
    },
  });

  const isNewPetParent = useMemo(
    () =>
      (!clinicPetParent?.createdAt || new Date(clinicPetParent.createdAt) > oneHourAgo) &&
      clinicPetParent?.creationSource === ClinicEntityCreationSource.DirectBooking,
    [clinicPetParent, oneHourAgo],
  );

  const { data: appointmentTypesData } = useGetDirectBookingAppointmentTypesQuery({
    variables: {
      data: {
        clinicId,
      },
    },
    fetchPolicy: GraphQLFetchPolicy.CacheAndNetwork,
  });

  const { data: providerApptTypeRoomMapData } = useGetProviderAppointmentTypeRoomMapQuery({
    variables: {
      clinicId,
    },
  });

  // legacy AVImark direct booking gets availability through VetData instead of Bitwerx
  const useVetDataAvailability = useMemo(
    () =>
      integrations.some(
        (integration: ClinicPimsIntegrationSelectionFragment) =>
          integration.isActive && integration.vetdata?.system === VetDataIntegrationSystem.Avimark,
      ),
    [integrations],
  );

  const appointmentTypeOptions = useMemo(() => {
    const getLabel = (type: AppointmentTypeFragment): string => {
      let label = type?.displayName || type?.name || '';
      if (type?.hasDeposit && type?.depositAmount) {
        label += ` - ${type?.depositCurrency === Currency.Usd ? '$' : ''}${type?.depositAmount}`;
        if (type?.markPaymentAsDeposit) {
          label += ' Deposit';
        }
      }
      return label;
    };

    return (
      appointmentTypesData?.getDirectBookingAppointmentTypes
        ?.filter(
          (type) =>
            type?.clinicEmployeeAppointmentTypeSettings && type?.clinicEmployeeAppointmentTypeSettings.length > 0,
        )
        ?.filter(
          (type) =>
            // check ProviderAppointmentTypeRoomMap for eliglible appt types for clinics with PIMS other than AVImark
            useVetDataAvailability ||
            !integrations.length ||
            providerApptTypeRoomMapData?.findManyProviderAppointmentTypeRoomMap?.some(
              (map) => map.appoinmentTypeId === type?.id,
            ),
        )
        ?.filter((type) => {
          const clientType =
            type?.directBookingAppointmentTypeSetting?.clientType || DirectBookingAppointmentTypeClientType.Both;

          return (
            clientType === DirectBookingAppointmentTypeClientType.Both ||
            (isNewPetParent
              ? clientType === DirectBookingAppointmentTypeClientType.NewClients
              : clientType === DirectBookingAppointmentTypeClientType.ExistingClients)
          );
        })
        ?.map((type) => {
          return {
            label: getLabel(type),
            description: type?.description || '',
            value: type?.name || '',
          };
        }) || []
    );
  }, [
    appointmentTypesData?.getDirectBookingAppointmentTypes,
    providerApptTypeRoomMapData?.findManyProviderAppointmentTypeRoomMap,
    useVetDataAvailability,
    integrations.length,
    isNewPetParent,
  ]);

  const pets = useMemo(() => clinicPetParent?.pets?.filter((pet) => Boolean(pet)) || [], [clinicPetParent]);

  const reasonLabel = useMemo(() => {
    if (env.NODE_ENV === 'production' && clinicId === ProdClinicIds.Harborside) {
      return 'Please note your preferred provider and reason for visit:';
    }

    return 'Please explain your reason for visit:';
  }, [clinicId]);

  const onSubmit = useCallback(
    (data: FieldValues): void => {
      const selectedPet = pets.find((pet) => pet.id === data.clinicPetId);
      const selectedAppointmentType = appointmentTypesData?.getDirectBookingAppointmentTypes?.find(
        (type) => type?.name === data.appointmentType,
      );

      const providersForAppointmentType = selectedAppointmentType?.clinicEmployeeAppointmentTypeSettings
        ?.map((mapping) => mapping?.clinicEmployee)
        .filter(
          (doctor) =>
            // only show doctors eligible for selected appt type
            useVetDataAvailability ||
            !integrations.length ||
            providerApptTypeRoomMapData?.findManyProviderAppointmentTypeRoomMap?.some(
              (map) => map.appoinmentTypeId === selectedAppointmentType?.id && map.clinicEmployeeId === doctor.id,
            ),
        );

      setAppointmentDetails({
        ...appointmentDetails,
        clinicPet: selectedPet,
        appointmentType: selectedAppointmentType,
        reasonForVisit: data.reasonForVisit,
        providersForAppointmentType,
      });

      goToNextStep();
    },
    [
      pets,
      appointmentTypesData?.getDirectBookingAppointmentTypes,
      setAppointmentDetails,
      appointmentDetails,
      goToNextStep,
      useVetDataAvailability,
      integrations.length,
      providerApptTypeRoomMapData?.findManyProviderAppointmentTypeRoomMap,
    ],
  );

  const { field } = useController({
    name: 'clinicPetId',
    control,
    rules: { required: 'Please select a pet.' },
  });

  const { getRootProps, getRadioProps, onChange } = useRadioGroup({
    ...field,
  });

  useEffect(() => {
    if (pets.length === 1) {
      onChange(pets[0].id);
    }
  }, [pets, onChange]);

  return (
    <Flex flexDir="column" alignSelf="stretch" overflow="auto">
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <FormRow>
          <Box {...getRootProps()} mb={4} data-testid="pet-selection-radio-group">
            <FormLabel>
              <Text fontWeight="semibold">Who are we helping today?</Text> <Text color="text.danger">{' *'}</Text>
            </FormLabel>
            <VStack w="100%" align="flex-start">
              {pets.map((pet) => (
                <PetRadio key={pet.id} pet={pet} {...getRadioProps({ value: pet.id })} />
              ))}
            </VStack>
          </Box>
          <Text size="xs">Don&apos;t see your pet? Please contact us to add one.</Text>
        </FormRow>
        <FormRow>
          <Controller
            control={control}
            name="appointmentType"
            rules={{ required: 'Please select a reason for your appointment.' }}
            render={({ field, fieldState }): JSX.Element => (
              <RadioGroup
                direction="column"
                label="Please select a reason for your appointment."
                labelStyle={{ fontWeight: 'semibold' }}
                isInvalid={fieldState.invalid}
                errorText={fieldState.error?.message}
                isRequired={true}
                onChange={field.onChange}
                defaultValue={field.value}
                radios={appointmentTypeOptions.map((apptTypeOption) => {
                  return {
                    children: (
                      <Box>
                        <Text as="p" size="sm" fontWeight="semibold">
                          {apptTypeOption.label}
                        </Text>
                        <Text as="p" size="sm" style={{ wordBreak: 'break-word' }}>
                          <Linkify tagName="span" options={{ target: '_blank', className: 'conversation-link' }}>
                            {apptTypeOption.description}
                          </Linkify>
                        </Text>
                      </Box>
                    ),
                    value: apptTypeOption.value,
                  };
                })}
                data-testid="visit-reason-radio-group"
              />
            )}
          />
        </FormRow>
        <FormRow>
          <Textarea
            resize="none"
            label={reasonLabel}
            labelStyle={{ fontWeight: 'semibold' }}
            isRequired={true}
            isInvalid={!!errors.reasonForVisit}
            {...register('reasonForVisit', { required: 'Please explain why you are scheduling this appointment.' })}
            errorText={errors.reasonForVisit?.message}
            data-testid="visit-reason-textarea"
          />
        </FormRow>

        <Box p={4} borderTopWidth={1}>
          <Button isFullWidth={true} type="submit" isDisabled={!isValid} data-testid="submit-button">
            Select Date &amp; Time
          </Button>
        </Box>
      </form>
    </Flex>
  );
};

export default AppointmentDetails;
