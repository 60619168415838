export enum FormQuestionType {
  Checkbox = 'checkbox',
  MultipleSelection = 'multipleSelection',
  TextInput = 'textInput',
  MultiLineTextInput = 'multiLineTextInput',
  RatingInput = 'ratingInput',
  DropDown = 'dropDown',
  DateInput = 'dateInput',
  TimeInput = 'timeInput',
  TextBlock = 'textBlock',
  SignatureInput = 'signatureInput',
}
