import React, { useCallback } from 'react';
import Linkify from 'linkifyjs/react';
import { ClinicWidgetRequestTypeResponse, ClinicWidgetSettingResponse } from 'shared/types';
import { GATrack } from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Card } from '@televet/kibble-ui/build/components/Card';
import { Flex, Circle } from '@televet/kibble-ui/build/chakra';
import { WidgetRequestTypeLabels } from '@televet/shared-types/widgetRequest/WidgetRequestTypeLabels';
import { useWidgetStore } from 'state/useWidgetStore';
import RequestTypeIcon from 'shared/components/RequestTypeIcon';

interface WidgetPreviewProps {
  widgetSetting: ClinicWidgetSettingResponse;
  onCloseClick: () => void;
}

const WidgetPreview = ({ widgetSetting, onCloseClick }: WidgetPreviewProps): JSX.Element => {
  const { setSelectedRequest, goToNextStep, onToggle, isMobile } = useWidgetStore((state) => state);
  const { welcomeMessage, clinicWidgetRequestTypes, clinic } = widgetSetting;

  const handleRequestTypeClick = useCallback(
    (request: ClinicWidgetRequestTypeResponse) => {
      setSelectedRequest(request);
      goToNextStep();
    },
    [goToNextStep, setSelectedRequest],
  );

  return (
    <Flex
      className="WidgetPreview__Container"
      w="310px"
      flexDir="column"
      align="flex-end"
      mr={isMobile ? 3 : 'unset'}
      mb={isMobile ? '92px' : '88px'}
      gap={3}
      data-testid="widget-preview-container"
    >
      <Button
        className="WidgetPreview__CloseButton"
        onClick={onCloseClick}
        borderRadius="full"
        h="34px"
        w="34px"
        size="sm"
        variant="secondarySubtle"
        bgColor="background.subtle"
      >
        <Icon name="close" size="md" />
      </Button>

      <Card className="WidgetPreview__WelcomeMessage" boxShadow="base">
        <Heading size="xs">Welcome to {clinic[0].name}!</Heading>

        {welcomeMessage && (
          <Linkify>
            <Text size="sm" mt={2} as="p">
              {welcomeMessage}
            </Text>
          </Linkify>
        )}
      </Card>

      {clinicWidgetRequestTypes?.slice(0, 2).map((request) => (
        <Button
          className="WidgetPreview__RequestButton"
          key={request.id}
          onClick={(): void => handleRequestTypeClick(request)}
          variant="secondarySubtle"
          boxShadow="base"
          gap={2}
          data-testid="request-type-button"
        >
          <RequestTypeIcon requestType={request.requestType} size="md" />
          <Text size="sm">{request?.displayName || WidgetRequestTypeLabels[request.requestType]}</Text>
          <Icon name="chevronRight" size="sm" />
        </Button>
      ))}

      {clinicWidgetRequestTypes && clinicWidgetRequestTypes.length > 2 && (
        <Button
          className="WidgetPreview__RequestButton--Other"
          onClick={(): void => {
            GATrack(GA4Events.OTHER_OPTIONS_CLICK, { clinicId: clinic[0].id });
            onToggle(true);
          }}
          variant="secondarySubtle"
          boxShadow="base"
          gap={2}
        >
          <Circle bgColor="background.contrast" sx={{ svg: { color: 'text.onContrast' } }}>
            <Icon name="dotsHorizontal" size="md" />
          </Circle>
          <Text size="sm">Other Options</Text>
          <Icon name="chevronRight" size="sm" />
        </Button>
      )}
    </Flex>
  );
};

export default WidgetPreview;
