import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { env } from 'env';
import { GA4Events } from 'shared/enums/GA4Events';
import * as Sentry from '@sentry/react';

interface GAProps {
  clinicId?: string;
}

interface Dict {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const isDevelopment = env.NODE_ENV === 'development';

export const GATrack = (name: string, params?: Dict): void => {
  if (isDevelopment && name.length > 40) {
    throw new Error('Event name needs to be 40 chars or less');
  }

  if (isDevelopment) {
    console.log(name, params);
  }

  ReactGA.event(name, params);
};

const useGA = ({ clinicId }: GAProps): void => {
  useEffect(() => {
    if (clinicId !== '' && !ReactGA.isInitialized) {
      try {
        const gaOptions = {
          ...(isDevelopment && { debug_mode: true }),
          custom_map: {
            dimension1: 'clinicId',
            dimension2: 'isPetPortal',
            dimension3: 'isMobileApp',
          },
        };

        ReactGA.initialize(env.REACT_APP_GA4_ID, { gaOptions });

        if (isDevelopment && ReactGA.isInitialized) {
          console.log(GA4Events.INIT, { id: env.REACT_APP_GA4_ID, gaOptions });
        }
      } catch (error) {
        if (!isDevelopment) {
          console.error(error);
          Sentry.captureException(error);
        }
      }
    }
  }, [clinicId]);
};

export default useGA;
