import React, { useRef, useEffect, useMemo } from 'react';
import { useMonth, FirstDayOfWeek } from '@datepicker-react/hooks';
import Day from './Day';
import isSameMonth from 'date-fns/isSameMonth';
import { Box, Flex, Center, Grid, GridItem } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';

interface IMonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek;
  currentDay: Date | null;
  dates: Date[] | null;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
  onDaySelected?: () => void;
  scrollIntoView?: boolean;
  loading?: boolean;
  maxBookingDate?: Date;
}
const Month = ({
  year,
  month,
  firstDayOfWeek,
  currentDay,
  dates,
  goToPreviousMonths,
  goToNextMonths,
  onDaySelected,
  scrollIntoView,
  loading,
  maxBookingDate,
}: IMonthProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  useEffect(() => {
    if (scrollIntoView && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [scrollIntoView]);

  const isDateAvailable = useMemo(
    () => (currentDate: Date): boolean => dates?.some((date) => date.getTime() === currentDate.getTime()) || false,
    [dates],
  );

  const isPreviousMonthAvailable = useMemo(() => {
    if (!currentDay) {
      return false;
    }

    return currentDay.getFullYear() === year ? currentDay.getMonth() < month : true;
  }, [currentDay, month, year]);

  const isNextMonthAvailable = useMemo(() => {
    if (!currentDay || !maxBookingDate) {
      return false;
    }

    return !isSameMonth(maxBookingDate, new Date(year, month));
  }, [currentDay, maxBookingDate, month, year]);

  return (
    <Box ref={ref}>
      <Flex align="center" justify="space-between" borderBottomWidth="2px" h="55px" px={4}>
        <Button
          data-testid="previous-month-button"
          iconName="chevronLeft"
          onClick={goToPreviousMonths}
          variant="ghostNeutral"
          size="sm"
          isDisabled={!isPreviousMonthAvailable}
        />

        <Text data-testid="date-picker-month">{monthLabel}</Text>

        <Button
          data-testid="next-month-button"
          iconName="chevronRight"
          onClick={goToNextMonths}
          variant="ghostNeutral"
          size="sm"
          isDisabled={!isNextMonthAvailable}
        />
      </Flex>

      <Box py={2} px={3}>
        <Grid templateColumns="repeat(7, 1fr)" my={2}>
          {weekdayLabels.map((weekdayLabel: string) => (
            <GridItem key={weekdayLabel}>
              <Center>
                <Text size="xs" variant="subtle">
                  {weekdayLabel}
                </Text>
              </Center>
            </GridItem>
          ))}
        </Grid>

        {loading ? (
          <Center height="175px">
            <Spinner />
          </Center>
        ) : (
          <Grid templateColumns="repeat(7, 1fr)" gridGap={2}>
            {days.map((day, index) =>
              typeof day === 'object' ? (
                <Day
                  key={day.date.toString()}
                  date={day.date}
                  dayLabel={day.dayLabel}
                  onDaySelected={onDaySelected}
                  isAvailable={isDateAvailable(day.date)}
                />
              ) : (
                <div key={index} />
              ),
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Month;
