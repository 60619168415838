import add from 'date-fns/add';
import addHours from 'date-fns/addHours';
import addMonths from 'date-fns/addMonths';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfDay from 'date-fns/startOfDay';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GA4Events } from 'shared/enums/GA4Events';
import { GraphQLFetchPolicy } from 'shared/enums/GraphQLFetchPolicy';
import { GATrack } from 'shared/hooks/useGA';
import { IAppointmentDetails } from 'shared/interfaces/IAppointmentDetails';
import { DirectBookingOpenTimeSlotRange, useGetDirectBookingAvailabilityDatesQuery } from 'shared/types/graphql';
import SelectionCalendar from './SelectionCalendar';
import { getMaxBookingRangeInMonths } from './utils';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Select, SelectOptionProps } from '@televet/kibble-ui/build/components/Select';
import { useConst, Flex } from '@televet/kibble-ui/build/chakra';
import { MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { useWidgetStore } from 'state/useWidgetStore';

const DEFAULT_DR_OPTION_VALUE = '0';

interface ICalendarProps {
  setAppointmentDetails: (details: IAppointmentDetails) => void;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  appointmentDetails: IAppointmentDetails | undefined;
}

const Calendar = ({
  setAppointmentDetails,
  selectedDate,
  setSelectedDate,
  appointmentDetails,
}: ICalendarProps): JSX.Element | null => {
  const { clinicId, goToNextStep } = useWidgetStore((state) => state);
  const [selectedDoctor, setSelectedDoctor] = useState(DEFAULT_DR_OPTION_VALUE);
  const d = useRef<Date>();

  const maxBookingRangeInMonths = useMemo(() => getMaxBookingRangeInMonths(clinicId), [clinicId]);

  // maintain Dr selection when navigating back to calendar
  useEffect(() => {
    const doctorIds = appointmentDetails?.providersForAppointmentType?.map((p) => p.id);

    if (appointmentDetails?.doctorId && doctorIds?.includes(appointmentDetails.doctorId)) {
      setSelectedDoctor(appointmentDetails.doctorId);
    }
  }, [appointmentDetails, setSelectedDoctor]);

  const appointmentSoonestAvailable = useMemo(() => {
    if (
      typeof appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours === 'undefined' ||
      appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours === null
    ) {
      return 24;
    }

    return appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours;
  }, [appointmentDetails]);

  const appointmentDuration = useMemo(() => appointmentDetails?.appointmentType?.defaultDurationInMinutes || 30, [
    appointmentDetails,
  ]);

  // need to use date without time in query vars to avoid repeated requests due to changing time
  const todaysDate = useConst(() => new Date(new Date().setHours(0, 0, 0)));

  const getFirstUTCDayOfFollowingMonth = useCallback((date: Date): Date => {
    const lastDayLocalTZ = lastDayOfMonth(date);

    return new Date(lastDayLocalTZ.setUTCHours(24));
  }, []);

  const startDate = useMemo(() => {
    const date =
      selectedDate && selectedDate.getMonth() !== todaysDate.getMonth()
        ? new Date(selectedDate.setDate(1))
        : todaysDate;
    d.current = date;

    return date;
  }, [selectedDate, todaysDate]);

  const endDate = useMemo(() => getFirstUTCDayOfFollowingMonth(startDate), [getFirstUTCDayOfFollowingMonth, startDate]);
  const minBookingDate = addHours(todaysDate, appointmentSoonestAvailable);
  const maxBookingDate = addMonths(todaysDate, maxBookingRangeInMonths);

  const getDates = useCallback((startDate: Date): DirectBookingOpenTimeSlotRange[] => {
    const ranges = [];

    let startTime = startOfDay(startDate);
    const endDate = add(startTime, { months: 1 }).setUTCDate(1);
    const endTime = new Date(endDate);

    while (startTime < endTime) {
      ranges.push({
        startDate: startTime,
        endDate: add(startTime, { days: 1 }),
      });

      startTime = add(startTime, { days: 1 });
    }

    return ranges;
  }, []);

  const {
    data: availabilityDatesData,
    loading: isLoadingAvailabilityDatesData,
    refetch: refetchDirectBookingAvailabilityDates,
  } = useGetDirectBookingAvailabilityDatesQuery({
    variables: {
      data: {
        clinicId,
        appointmentTypeId: appointmentDetails?.appointmentType?.id,
        dates: getDates(d.current ? d.current : startDate),
        clinicEmployeeIds:
          selectedDoctor !== '0' ? [selectedDoctor] : appointmentDetails?.providersForAppointmentType?.map((p) => p.id),
        durationInMinutes: appointmentDuration,
        soonestAvailableBookingInHours: appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours,
        showFirstAvailabilityDayOnly: true,
        petParentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    skip: !clinicId,
    fetchPolicy: GraphQLFetchPolicy.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });

  const doctorOnSelect = useCallback(
    (selectedItem: MenuItemProps) => {
      const clinicEmployeeIds = selectedItem.value
        ? [selectedItem.value]
        : appointmentDetails?.providersForAppointmentType?.map((p) => p.id);

      refetchDirectBookingAvailabilityDates({
        data: {
          clinicId,
          dates: getDates(d.current ? d.current : startDate),
          appointmentTypeId: appointmentDetails?.appointmentType?.id,
          clinicEmployeeIds,
          durationInMinutes: appointmentDuration,
          soonestAvailableBookingInHours: appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours,
          showFirstAvailabilityDayOnly: true,
        },
      });

      setSelectedDoctor(selectedItem.value || DEFAULT_DR_OPTION_VALUE);
      setAppointmentDetails({
        ...appointmentDetails,
        doctorId: selectedItem.value,
        doctorName: selectedItem.label as string,
      });
    },
    [
      appointmentDetails,
      appointmentDuration,
      clinicId,
      getDates,
      refetchDirectBookingAvailabilityDates,
      setAppointmentDetails,
      startDate,
    ],
  );

  const doctorOptions = useMemo(() => {
    const defaultOptions: SelectOptionProps[] = [
      {
        label: 'Any Available Provider',
        value: DEFAULT_DR_OPTION_VALUE,
        isSelected: true,
        variant: 'default',
      },
    ];

    if (!appointmentDetails?.providersForAppointmentType) {
      return defaultOptions;
    }

    return defaultOptions.concat(
      appointmentDetails?.providersForAppointmentType?.map((provider) => ({
        label: `${provider.firstName || ''} ${provider.lastName}`,
        value: provider.id,
        variant: 'default',
      })),
    );
  }, [appointmentDetails?.providersForAppointmentType]);

  const availableDates = useMemo(() => {
    if (!availabilityDatesData?.getDirectBookingOpenTimeSlots2?.availableTimeSlots) return [];

    const availability = availabilityDatesData?.getDirectBookingOpenTimeSlots2?.availableTimeSlots?.flatMap(
      (doctor) => doctor?.availability,
    );

    return availability
      .filter((day) => day?.hasAvailability && !!day?.bookingDate)
      .map((day) => {
        const localMidnight = new Date(day?.bookingDate || '').setHours(24);

        return new Date(localMidnight);
      });
  }, [availabilityDatesData]);

  const availableDateStrings = useMemo(() => availableDates.map((date) => date.toDateString()), [availableDates]);

  const onMonthChange = useCallback(
    (date: Date): void => {
      if (isLoadingAvailabilityDatesData || date.getMonth() === d.current?.getMonth()) return;

      const isCurrentMonth = date.getMonth() === todaysDate.getMonth();
      d.current = isCurrentMonth ? todaysDate : date;

      refetchDirectBookingAvailabilityDates({
        data: {
          clinicId,
          dates: getDates(isCurrentMonth ? todaysDate : date),
          appointmentTypeId: appointmentDetails?.appointmentType?.id,
          clinicEmployeeIds: appointmentDetails?.doctorId
            ? [appointmentDetails.doctorId]
            : appointmentDetails?.providersForAppointmentType?.map((p) => p.id),
          durationInMinutes: appointmentDuration,
          soonestAvailableBookingInHours: appointmentDetails?.appointmentType?.defaultSoonestAvailableBookingInHours,
          showFirstAvailabilityDayOnly: true,
        },
      });
    },
    [
      isLoadingAvailabilityDatesData,
      refetchDirectBookingAvailabilityDates,
      clinicId,
      getDates,
      appointmentDetails,
      appointmentDuration,
      todaysDate,
    ],
  );

  const onDateSelected = useCallback(
    (startDate: Date | null): void => {
      if (startDate && !availableDateStrings.includes(startDate.toDateString())) return;

      setSelectedDate(startDate);
      goToNextStep();
    },
    [availableDateStrings, setSelectedDate, goToNextStep],
  );

  return (
    <Flex flexDir="column" bgColor="background.subtle" flex="auto" alignSelf="stretch">
      <Heading size="sm" p={2} bgColor="background.default">
        Select a Day for Your Appointment
      </Heading>

      <Flex pt={2} px={5}>
        <Select
          isFieldInline={true}
          value={selectedDoctor || ''}
          onBlur={(): void => GATrack(GA4Events.DIRECT_BOOKING_DOCTOR_SELECT, { clinicId })}
          listProps={{
            onSelect: doctorOnSelect,
          }}
          label={
            <Flex align="center" gap={2}>
              <Icon name="filter" size="sm" />
              <Text size="sm">Filter by Provider:</Text>
            </Flex>
          }
          options={doctorOptions}
          data-testid="provider-select"
        />
      </Flex>
      <SelectionCalendar
        startDate={startDate}
        endDate={endDate}
        dates={availableDates}
        currentDay={new Date()}
        minBookingDays={1}
        onDateChange={onDateSelected}
        onMonthChange={onMonthChange}
        exactMinBookingDays={true}
        minBookingDate={minBookingDate}
        maxBookingDate={maxBookingDate}
        loading={isLoadingAvailabilityDatesData}
      />
    </Flex>
  );
};

export default Calendar;
