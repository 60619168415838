import * as Sentry from '@sentry/react';
import { Flex, useConst } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { DatePicker } from '@televet/kibble-ui/build/components/DatePicker';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { Select, SelectOptionProps } from '@televet/kibble-ui/build/components/Select';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import React, { KeyboardEvent, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DevClinicIds, ProdClinicIds } from 'shared/enums/ClinicMap';
import { GA4Events } from 'shared/enums/GA4Events';
import { CanadaRegions, USRegions } from 'shared/enums/Regions';
import { Species } from 'shared/enums/Species';
import { GATrack } from 'shared/hooks/useGA';
import { useWidgetStore } from 'state/useWidgetStore';
import {
  ClinicEntityCreationSource,
  ClinicPetCreateWithoutPetParentsInput,
  ClinicPimsIntegrationSelectionFragment,
  ClinicPimsIntegrationType,
  useCreateParentAndPatientMutation,
  useGetNewPetOptionsQuery,
  useGetSexesQuery,
  WidgetPetParentFragment,
} from 'shared/types/graphql';
import {
  email as emailAddressRegex,
  isAValidCanadianPostalCode,
  phone as phoneNumberRegex,
  digit as digitRegex,
} from 'shared/utils/validation';
import { titleCase } from './utils/titleCase';

const DefaultSpeciesOptions: SelectOptionProps[] = [
  ...Object.entries(Species).map(([key, value]) => ({ label: value, value: key })),
];

const USRegionOptions: SelectOptionProps[] = [
  ...Object.entries(USRegions).map(([key, value]) => ({ label: key, value: value, groupLabel: 'States' })),
];

const CanadaRegionOptions: SelectOptionProps[] = [
  ...Object.entries(CanadaRegions).map(([key, value]) => ({ label: key, value: value, groupLabel: 'Provinces' })),
];

const DefaultSexOptions: SelectOptionProps[] = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Unknown', value: 'unknown' },
];

interface ICreateAccountProps {
  integrations: ClinicPimsIntegrationSelectionFragment[];
}

interface ICreateAccountFormValues {
  firstName: string;
  lastName: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
  };
  email: string;
  phone: string;
  pet: {
    name: string;
    dateOfBirth: Date;
    species: string;
    breed: string;
    gender: string;
    weight: string;
    color: string;
  };
}

const CreateAccount = ({ integrations }: ICreateAccountProps): JSX.Element => {
  const {
    clinicId,
    authentication: { emailAddress, phoneNumber },
    goToNextStep,
    updateWidgetState,
  } = useWidgetStore((state) => state);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    control,
    clearErrors,
  } = useForm<ICreateAccountFormValues>({
    defaultValues: {
      email: emailAddress,
      phone: phoneNumber?.replace(/\D+/g, ''),
    },
    reValidateMode: 'onChange',
  });
  const watchSpecies = watch('pet.species');

  const today = useConst(() => new Date());

  const bitwerxIntegration = useMemo(() => {
    const integration = integrations.find(
      ({ type, isActive }) => type === ClinicPimsIntegrationType.Bitwerx && isActive,
    );
    return integration || null;
  }, [integrations]);

  const vetDataIntegrationId = useMemo(() => {
    const integration = integrations.find(
      ({ type, isActive }) => type === ClinicPimsIntegrationType.Vetdata && isActive,
    );
    return integration?.id;
  }, [integrations]);

  const { data: petOptions } = useGetNewPetOptionsQuery({
    variables: {
      integrationId: bitwerxIntegration?.id || '',
    },
    skip: !bitwerxIntegration,
  });

  const { data: sexOptions } = useGetSexesQuery({
    variables: {
      integrationId: bitwerxIntegration?.id || '',
    },
    skip: !bitwerxIntegration,
  });

  const filteredSexOptions = useMemo(() => {
    let sexes = sexOptions?.findManySex?.filter((sex) => sex.speciesId === watchSpecies);

    if (!sexes?.length) {
      sexes = sexOptions?.findManySex?.filter((sex) => sex.speciesId === null);
    }

    return sexes
      ?.map((sex) => {
        return {
          label: sex.name,
          value: sex.id,
        };
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [sexOptions?.findManySex, watchSpecies]);

  // Caring Pathways is a multisite clinic with some specific overrides that will be replaced with settings in future work
  const isCaringPathways = useMemo(() => {
    return ([
      ProdClinicIds.CaringPathwaysCentral,
      ProdClinicIds.CaringPathwaysEastern,
      ProdClinicIds.CaringPathwaysMountain,
      ProdClinicIds.CaringPathwaysPacific,
      DevClinicIds.ABCDemo,
    ] as string[]).includes(clinicId);
  }, [clinicId]);

  const [createAccount, { loading: creatingAccount }] = useCreateParentAndPatientMutation();

  const onSubmit = async (data: ICreateAccountFormValues): Promise<void> => {
    try {
      const petData: ClinicPetCreateWithoutPetParentsInput = {
        name: data.pet.name,
        weight: parseInt(data.pet.weight),
        color: data.pet.color,
        dateOfBirth: new Date(data.pet.dateOfBirth),
      };

      if (bitwerxIntegration) {
        const selectedSpecies = petOptions?.findManySpecies?.find((species) => species.id === data.pet.species);
        const selectedBreed = selectedSpecies?.breeds.find((breed) => breed.id === data.pet.breed);
        const selectedSex = sexOptions?.findManySex?.find((sex) => sex.id === data.pet.gender);

        petData.speciesModel = {
          connect: {
            id: selectedSpecies?.id,
          },
        };
        petData.breedModel = {
          connect: {
            id: selectedBreed?.id,
          },
        };
        petData.sexModel = {
          connect: {
            id: selectedSex?.id,
          },
        };
        petData.species = selectedSpecies?.name;
        petData.breed = selectedBreed?.name;
        petData.gender = selectedSex?.name;
      } else {
        petData.species = data.pet.species;
        petData.breed = data.pet.breed;
        petData.gender = data.pet.gender;
      }

      const integration = vetDataIntegrationId
        ? {
            integration: {
              connect: { id: vetDataIntegrationId },
            },
          }
        : undefined;

      const account = await createAccount({
        variables: {
          data: {
            clinic: {
              connect: { id: clinicId },
            },
            firstName: data.firstName,
            lastName: data.lastName,
            addresses: {
              create: [{ ...data.address }],
            },
            email: data.email,
            phoneNumbers: {
              create: [
                {
                  number: data.phone.replace(/\D/g, ''),
                  isPrimary: true,
                },
              ],
            },
            pets: {
              create: [
                {
                  clinic: {
                    connect: { id: clinicId },
                  },
                  ...integration,
                  ...petData,
                },
              ],
            },
            creationSource: ClinicEntityCreationSource.DirectBooking,
            ...integration,
          },
          skipPrescriptions: true,
          skipEnrollments: true,
        },
      });

      const petParent = account?.data?.createOneClinicPetParent || ({} as WidgetPetParentFragment);

      GATrack(GA4Events.DIRECT_BOOKING_APPT_INTAKE_SUCCESS, {
        clinicId,
        petParentId: petParent?.id,
        petId: petParent?.pets?.[0]?.id,
      });

      updateWidgetState({ clinicPetParent: petParent });
      goToNextStep();

      const directBookingContainer = document.getElementById('directBookingContainer');
      if (directBookingContainer) {
        directBookingContainer.scrollTop = 0;
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  const speciesOptions = useMemo(() => {
    if (!!bitwerxIntegration) {
      const filteredSpecies = petOptions?.findManySpecies?.filter((species) => species.breeds.length > 0);

      return filteredSpecies
        ?.map((species) => {
          return {
            label: titleCase(species.name),
            value: species.id,
          };
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    } else {
      return DefaultSpeciesOptions;
    }
  }, [bitwerxIntegration, petOptions?.findManySpecies]);

  const breedOptions = useMemo(() => {
    if (!!bitwerxIntegration) {
      const selectedSpecies = petOptions?.findManySpecies?.find((species) => species.id === watchSpecies);
      return selectedSpecies?.breeds
        .map((breed) => {
          return {
            label: breed.name,
            value: breed.id,
          };
        })
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }
  }, [bitwerxIntegration, petOptions?.findManySpecies, watchSpecies]);

  const handleSpeciesSelect = (selectedItem: MenuItemProps): void => {
    setValue('pet.species', selectedItem.value || '');
    clearErrors(['pet.breed', 'pet.gender']);
  };

  const handleBreedSelect = (selectedItem: MenuItemProps): void => setValue('pet.breed', selectedItem.value || '');
  const handleGenderSelect = (selectedItem: MenuItemProps): void => setValue('pet.gender', selectedItem.value || '');

  const handleNumberFieldKeyDown = (event: KeyboardEvent<HTMLInputElement>): boolean => {
    if (isNaN(parseInt(event.key)) && event.key !== 'Backspace') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <Flex flexDir="column">
      <Flex w="100%" py={2.5} px={5} bgColor="background.contrast">
        <Text size="sm" color="text.onContrast">
          {`${isCaringPathways ? '' : '👋 Welcome! '}Let's finish setting up your account.`}
        </Text>
      </Flex>

      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Flex flexDir="column" m={6} gap={6}>
          <Text>Please fill out the information below before proceeding to book an appointment.</Text>
          <Flex flexDir="column" gap={6}>
            <Heading size="md">Your Information</Heading>
            {/* TODO: move more of the shared validation logic into the validation util */}
            <TextInput
              maxLength={25}
              label="First Name"
              autoFocus={true}
              isRequired={true}
              isInvalid={!!errors.firstName}
              errorText={errors.firstName?.message?.toString()}
              {...register('firstName', {
                required: 'You must specify a first name',
                maxLength: { value: 25, message: 'First name must be 25 characters or less' },
              })}
            />
            <TextInput
              maxLength={25}
              label="Last Name"
              isRequired={true}
              isInvalid={!!errors.lastName}
              errorText={errors.lastName?.message?.toString()}
              {...register('lastName', {
                required: 'You must specify a last name',
                maxLength: { value: 25, message: 'Last name must be 25 characters or less' },
              })}
            />
            <TextInput
              maxLength={25}
              label="Address"
              isRequired={true}
              isInvalid={!!errors.address?.line1}
              errorText={errors.address?.line1?.message?.toString()}
              {...register('address.line1', {
                required: 'You must specify an address',
                maxLength: { value: 25, message: 'Address must be 25 characters or less' },
              })}
            />
            <TextInput
              maxLength={25}
              label="Address Line 2"
              isInvalid={!!errors.address?.line2}
              errorText={errors.address?.line2?.message?.toString()}
              {...register('address.line2', {
                maxLength: { value: 25, message: 'Address Line 2 must be 25 characters or less' },
              })}
            />
            <TextInput
              maxLength={20}
              label="City"
              isRequired={true}
              isInvalid={!!errors.address?.city}
              errorText={errors.address?.city?.message?.toString()}
              {...register('address.city', {
                required: 'You must specify a city',
                maxLength: { value: 20, message: 'City must be 20 characters or less' },
              })}
            />
            <Flex gap={3}>
              <Controller
                control={control}
                rules={{
                  required: 'You must specify a state or province',
                }}
                name="address.stateProvince"
                render={({ fieldState, field: { onChange, value } }): JSX.Element => (
                  <Select
                    label="State/Province"
                    placeholder="Please Select One"
                    isRequired={true}
                    value={value}
                    isInvalid={fieldState.invalid}
                    errorText={fieldState.error?.message}
                    options={[...USRegionOptions, ...CanadaRegionOptions]}
                    listProps={{
                      isSearchable: true,
                      onSelect: (selectedItem: MenuItemProps): void => onChange(selectedItem.value),
                      isGrouped: true,
                    }}
                  />
                )}
              />
              <TextInput
                placeholder="12345 or A1A 1A1"
                maxLength={7}
                minLength={5}
                label="Zip"
                isRequired={true}
                isInvalid={!!errors.address?.postalCode}
                errorText={errors.address?.postalCode?.message?.toString()}
                {...register('address.postalCode', {
                  required: 'You must specify a zip code',
                  maxLength: { value: 7, message: 'Zip code must be less than 7 digits' },
                  minLength: { value: 5, message: 'Zip code must be 5 digits' },
                  validate: {
                    isProvided: (value: string): boolean | string => {
                      if (isNaN(parseInt(value))) {
                        return isAValidCanadianPostalCode(value) || 'Please provide a valid zip code';
                      }
                      return value.length === 5 || 'Please provide a valid zip code';
                    },
                  },
                })}
              />
            </Flex>
            <TextInput
              maxLength={100}
              label="Email Address"
              type="email"
              isRequired={true}
              isInvalid={!!errors.email}
              isDisabled={!!emailAddress}
              errorText={errors.email?.message?.toString()}
              {...register('email', {
                required: 'You must specify a valid email',
                pattern: { value: emailAddressRegex, message: 'You must specify a valid email' },
                maxLength: { value: 100, message: 'Email must be 100 characters or less' },
              })}
            />
            <TextInput
              maxLength={10}
              label="Phone Number"
              type="tel"
              helperText="Numbers only, 10 character limit"
              onKeyDown={handleNumberFieldKeyDown}
              isRequired={true}
              isInvalid={!!errors.phone}
              isDisabled={!!phoneNumber}
              errorText={errors.phone?.message?.toString()}
              {...register('phone', {
                required: 'You must specify a valid phone number',
                pattern: { value: phoneNumberRegex, message: 'You must specify a valid phone number' },
                maxLength: { value: 10, message: 'Phone number must be 10 characters or less' },
              })}
            />
          </Flex>
        </Flex>
        <Flex bgColor="background.primaryAlpha">
          <Flex direction="column" m={6} gap={6} w="100%">
            <Heading size="md">Pet&apos;s Information</Heading>
            <TextInput
              maxLength={25}
              label="Pet Name"
              isRequired={true}
              isInvalid={!!errors.pet?.name}
              errorText={errors.pet?.name?.message?.toString()}
              {...register('pet.name', {
                required: 'You must specify a pet name',
                maxLength: { value: 25, message: 'Pet name must be 25 characters or less' },
              })}
            />
            <DatePicker
              startDate={null}
              maxDate={today}
              maxYear={today.getFullYear()}
              onDateChange={({ startDate }): void => {
                if (startDate) {
                  setValue('pet.dateOfBirth', startDate);
                }
              }}
              inputProps={{
                label: 'Date of Birth',
                placeholder: '00/00/0000',
                isRequired: true,
                isInvalid: !!errors.pet?.dateOfBirth,
                errorText: errors.pet?.dateOfBirth?.message?.toString(),
                ...register('pet.dateOfBirth', { required: 'You must specify a date of birth' }),
              }}
            />

            <Controller
              control={control}
              name="pet.species"
              rules={{ required: 'You must specify a species' }}
              render={({ fieldState }): JSX.Element => (
                <Select
                  label="Species"
                  placeholder="Please Select One"
                  isRequired={true}
                  isInvalid={fieldState.invalid}
                  errorText={fieldState.error?.message}
                  options={speciesOptions || []}
                  listProps={{
                    onSelect: handleSpeciesSelect,
                    maxH: 200,
                    zIndex: 'popover',
                  }}
                />
              )}
            />

            {(!bitwerxIntegration && watchSpecies) === Species.Other && (
              <TextInput name="pet.species" label="Other Species" />
            )}

            {!!bitwerxIntegration ? (
              <Controller
                control={control}
                name="pet.breed"
                rules={{ required: 'You must specify a breed' }}
                render={({ fieldState }): JSX.Element => (
                  <Select
                    label="Breed"
                    placeholder="Please Select One"
                    isRequired={true}
                    isInvalid={fieldState.invalid}
                    errorText={fieldState.error?.message}
                    isDisabled={!watchSpecies}
                    helperText="Please select a species before selecting breed."
                    options={breedOptions || []}
                    listProps={{
                      onSelect: handleBreedSelect,
                      maxH: 200,
                      zIndex: 'popover',
                      isSearchable: true,
                    }}
                  />
                )}
              />
            ) : (
              <TextInput
                label="Breed"
                isRequired={true}
                isInvalid={!!errors.pet?.breed}
                errorText={errors.pet?.breed?.message?.toString()}
                {...register('pet.breed', { required: 'You must specify a breed' })}
              />
            )}

            <Controller
              control={control}
              name="pet.gender"
              rules={{ required: 'You must specify a sex' }}
              render={({ fieldState }): JSX.Element => (
                <Select
                  label="Sex"
                  placeholder="Please Select One"
                  isRequired={true}
                  isInvalid={fieldState.invalid}
                  isDisabled={!watchSpecies}
                  errorText={fieldState.error?.message}
                  helperText="Please select a species before selecting sex."
                  options={filteredSexOptions || DefaultSexOptions}
                  listProps={{
                    onSelect: handleGenderSelect,
                    maxH: 200,
                  }}
                />
              )}
            />

            <TextInput
              maxLength={8}
              onKeyDown={handleNumberFieldKeyDown}
              label="Weight (lbs)"
              isRequired={true}
              isInvalid={!!errors.pet?.weight}
              errorText={errors.pet?.weight?.message?.toString()}
              {...register('pet.weight', {
                required: 'You must specify a valid weight',
                pattern: { value: digitRegex, message: 'You must specify a valid weight' },
                maxLength: { value: 8, message: 'Pet weight must be 8 digits or less' },
              })}
            />
            {/*
            // TODO: hidden for now as not required and waiting on Bitwerx
            // ticket or back-end workaround
            */}
            {/* <TextInput name="pet.color" ref={register()} label="Color" /> */}
          </Flex>
        </Flex>
        <Flex p={4}>
          <Button
            data-testid="submit-button"
            type="submit"
            isLoading={creatingAccount}
            loadingText={'Creating account...'}
            isFullWidth={true}
          >
            Next: Choose Appointment
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default CreateAccount;
