export const email = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phone = /^\+?(1|0)?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const digit = /^\d*$/;
export const url = /\b((http|https):\/\/?)[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/;

export const isAValidCanadianPostalCode = (
  postalCode: string,
  spaceOption: 'required' | 'optional' | 'not_allowed' = 'optional',
): boolean => {
  let postalCodePattern;

  switch (spaceOption) {
    case 'required':
      postalCodePattern = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] [0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;
      break;
    case 'not_allowed':
      postalCodePattern = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;
      break;
    case 'optional':
    default:
      postalCodePattern = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;
      break;
  }

  return postalCodePattern.test(postalCode.toUpperCase());
};
