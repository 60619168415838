export enum ProdClinicIds {
  CaringPathwaysCentral = 'clbzmnbfo112440801uqvu64m3zq',
  CaringPathwaysEastern = 'clbzm83nl23058301s60t7pc5st',
  CaringPathwaysMountain = 'cl6zkx9g412711101xxxphaammj',
  CaringPathwaysPacific = 'clbzm3zqh444001wqa7w7xsvp',
  GoodShepherdBrokenArrow = 'clb16s8xm14052301w3vmtp3i1v',
  Harborside = 'cku8i70h3cln20800dn8mt8yk',
  Hurricane = 'ckbviekt704kk0729orkyzwog',
  ShoreHaven = 'cl5x02b0528708701yfmcanq2ox',
  Otto = 'ck7zasyqv007c0808szbhsagg',
}

export enum DevClinicIds {
  AvimarkTest = 'ck9iytproephb07455z7ohkk3',
  BellsAutomated = 'ckfy4mzd805yn0712ia7t9o8i',
  RealBaxter = 'ckg9vu23tl7c4071287j3bmed',
  ABCDemo = 'ckbs1cmaf81fx0745tz2xl3mn',
}
