import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { ClinicPet, useSubmitPrescriptionRefillRequestMutation, WidgetRequestType } from 'shared/types/graphql';
import {
  FormSubmissionMap,
  PrescriptionRefillRequest,
  PrescriptionRefillRequestFormSubmission,
  RefillRequestResponse,
} from '../../PrescriptionRefill/types';
import SelectPetCard from '../../PrescriptionRefill/components/SelectPetCard';
import { postRequestSubmittedEvent } from 'shared/utils/requestSubmitted';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Box, Divider, VStack } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { useWidgetStore } from 'state/useWidgetStore';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { ClinicPhoneOrEmailLinks } from 'shared/components/ClinicPhoneOrEmailLinks';

interface ISelectPetProps {
  setSelectedPet: (pet: ClinicPet) => void;
  formSubmission: FormSubmissionMap | undefined;
  setFormSubmission: Dispatch<SetStateAction<FormSubmissionMap>>;
  setCareConversationId: (id: string) => void;
}

const SelectPet = ({
  setSelectedPet,
  formSubmission,
  setFormSubmission,
  setCareConversationId,
}: ISelectPetProps): JSX.Element | null => {
  const {
    clinicId,
    parentUrl,
    clinicPetParent,
    selectedRequest,
    clinicName,
    goBackToMainMenu,
    goToStep,
    isPetPortal,
  } = useWidgetStore((state) => state);

  const pets = useMemo(() => clinicPetParent?.pets?.filter((pet): pet is ClinicPet => Boolean(pet)) || [], [
    clinicPetParent,
  ]);
  const isPetSubmissionWithPrescriptionsValid = useCallback(
    (petRecord: PrescriptionRefillRequestFormSubmission) =>
      (petRecord.form && petRecord.form.isValid && !!petRecord.prescriptions?.length) ||
      (petRecord.form && petRecord.form.isValid && petRecord.note !== ''),
    [],
  );
  const isPetSubmissionWithoutPrescriptionsValid = useCallback(
    (petRecord: PrescriptionRefillRequestFormSubmission) => !petRecord.form && petRecord.note !== '',
    [],
  );
  const isReadyForSubmission = useMemo(() => {
    if (formSubmission) {
      const petRecords: PrescriptionRefillRequestFormSubmission[] = Object.values(formSubmission);
      const isValidArr = petRecords.map((petRecord: PrescriptionRefillRequestFormSubmission) => {
        return isPetSubmissionWithPrescriptionsValid(petRecord) || isPetSubmissionWithoutPrescriptionsValid(petRecord);
      });

      return !!isValidArr.some((bool) => bool === true);
    }
  }, [formSubmission, isPetSubmissionWithPrescriptionsValid, isPetSubmissionWithoutPrescriptionsValid]);

  const [submitRequestForm, { loading }] = useSubmitPrescriptionRefillRequestMutation();

  const handleSubmitRequest = async (): Promise<void> => {
    let selectedPrescriptions: string[] = [];

    if (formSubmission) {
      const petRecords: PrescriptionRefillRequestFormSubmission[] = Object.values(formSubmission);
      petRecords.forEach((petRecord: PrescriptionRefillRequestFormSubmission) => {
        const { prescriptions, id } = petRecord;
        if (!isPetSubmissionWithPrescriptionsValid(petRecord) && !isPetSubmissionWithoutPrescriptionsValid(petRecord)) {
          delete formSubmission[id];
        }

        if (isPetSubmissionWithPrescriptionsValid(petRecord)) {
          const petPrescriptionsIds = prescriptions?.map((prescription) => prescription.id) || [];
          selectedPrescriptions = [...selectedPrescriptions, ...petPrescriptionsIds];
        }
      });

      try {
        if (!selectedRequest) return;
        const data: PrescriptionRefillRequest = {
          petParentId: clinicPetParent?.id || '',
          clinicId,
          formSubmission,
          prescriptionsIds: selectedPrescriptions,
          clinicWidgetRequestTypeId: selectedRequest?.id,
        };

        const response = await submitRequestForm({
          variables: {
            data,
          },
        });
        const responseData = response.data?.submitPrescriptionRefillRequest as RefillRequestResponse;

        postRequestSubmittedEvent(parentUrl, {
          requestType: WidgetRequestType.AdvancedRequestRxRefill,
          isNewClient: false,
          clinicName: clinicName || '',
          clinicId,
          channelId: responseData.channelId,
        });

        if (responseData.success && !isPetPortal) {
          setCareConversationId(responseData.channelId || '');
          goToStep('RefillConfirmation');
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  };

  return (
    <VStack>
      <Box p={4} w="100%" h="100%" className="Step__SelectPet-Container">
        {!!pets.length ? (
          <VStack w="100%" className="Step__SelectPet-List" justify="center" align="flex-start">
            <Text className="Step__SelectPet-Question">
              Which pet would you like to make a prescription refill for?
            </Text>
            {pets.map((pet) => (
              <SelectPetCard
                key={pet.id}
                pet={pet}
                setSelectedPet={setSelectedPet}
                formSubmission={formSubmission}
                setFormSubmission={setFormSubmission}
              />
            ))}
          </VStack>
        ) : (
          <VStack h="100%" spacing={5} align="flex-start" justify="">
            <Alert w="100%" title="No Pets Found" status="warning" hideCloseButton />
            <Text>{`We couldn't find a pet associated with your account. Please contact the clinic directly to add a pet to your account.`}</Text>
            <ClinicPhoneOrEmailLinks />
            <Button onClick={goBackToMainMenu} isFullWidth>
              Back to options
            </Button>
          </VStack>
        )}
      </Box>
      <Divider />
      <Box p={4} w="100%" className="Step__SelectPet-Submit">
        <Button onClick={handleSubmitRequest} isFullWidth isDisabled={!isReadyForSubmission} isLoading={loading}>
          Submit Request
        </Button>
      </Box>
    </VStack>
  );
};

export default SelectPet;
