import React, { PropsWithChildren } from 'react';
import { Flex, Box } from '@televet/kibble-ui/build/chakra';

interface IFormRow {
  horizontal?: boolean;
}

const FormRow = ({ children, horizontal }: PropsWithChildren<IFormRow>): JSX.Element =>
  horizontal ? (
    <Flex
      className="Request__FormRow"
      py={6}
      px={5}
      flexDir="column"
      _odd={{ bgColor: 'background.subtle' }}
      sx={{
        '& > *': { mx: 1, flex: 'auto' },
        '& > *:first-of-type': { ml: 0 },
        '& > *:last-child': { mr: 0 },
      }}
    >
      {children}
    </Flex>
  ) : (
    <Box
      className="Request__FormRow"
      py={6}
      px={5}
      _odd={{ bgColor: 'background.subtle' }}
      sx={{
        '& > *': { mx: 0, flex: 'unset' },
        '& > *:first-of-type': { ml: 0 },
        '& > *:last-child': { mr: 0 },
      }}
    >
      {children}
    </Box>
  );

export default FormRow;
