import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { decodeAuthToken, setAuthToken } from 'shared/auth';
import {
  useGenerateWidgetAuthenticationRequestMutation,
  useVerifyWidgetAuthenticationRequestMutation,
} from 'shared/types/graphql';
import { Flex, HStack, PinInput, PinInputField } from '@televet/kibble-ui/build/chakra';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Alert } from '@televet/kibble-ui/build/components/Alert';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { useWidgetStore } from 'state/useWidgetStore';
import { Spinner, useToast } from '@televet/kibble-ui';

export enum AccessCodeTransferMethod {
  Email = 'Email',
  Phone = 'Phone',
}

const MAX_AUTH_SUBMISSION_ATTEMPTS = 3;

const scrambleAccessCodeTransferAddress = (
  accessCodeTransferAddress: string | undefined,
  accessCodeTransferMethod: AccessCodeTransferMethod | undefined,
): string | undefined => {
  if (!accessCodeTransferAddress || !accessCodeTransferMethod) {
    return '';
  }

  if (accessCodeTransferMethod === AccessCodeTransferMethod.Phone) {
    const lastTwoDigits = accessCodeTransferAddress?.slice(-2);

    return lastTwoDigits;
  }

  if (accessCodeTransferMethod === AccessCodeTransferMethod.Email) {
    const firstThreeLetters = accessCodeTransferAddress?.slice(0, 3);

    return firstThreeLetters;
  }
};

const OneTimePassword = (): JSX.Element => {
  const {
    directBooking: { canCreateClientPatient },
    authentication: { accessCodeTransferMethod, accessCodeTransferAddress, emailAddress, phoneNumber },
    clinicId,
    updateWidgetAuthState,
    updateWidgetState,
    goToNextStep,
    goToStep,
  } = useWidgetStore((state) => state);
  const toast = useToast();

  const firstPinInputRef = useRef(null);
  const [password, setPassword] = useState('');
  const [numOfAttempts, setNumOfAttempts] = useState<number>(0);
  const [hasAuthCodeError, setHasAuthCodeError] = useState<boolean>(false);
  const [resendLabel, setResendLabel] = useState('');

  const [
    verifyWidgetAuthenticationRequest,
    { loading: isVerifyingRequest },
  ] = useVerifyWidgetAuthenticationRequestMutation();

  const [generateAuthRequest, { loading: isGeneratingAuthRequest }] = useGenerateWidgetAuthenticationRequestMutation({
    variables: {
      where: {
        clinicId,
        email: emailAddress || undefined,
        phoneNumber: phoneNumber?.replace(/\D/g, '') || undefined,
      },
    },
  });

  const generateOneTimeCode = useCallback(async (): Promise<void> => {
    if (isGeneratingAuthRequest) return;

    try {
      const { data } = await generateAuthRequest();

      updateWidgetAuthState({
        accessCodeTransferAddress: phoneNumber ? phoneNumber : emailAddress,
        accessCodeTransferMethod: phoneNumber ? AccessCodeTransferMethod.Phone : AccessCodeTransferMethod.Email,
      });

      const otcDelivered = data?.generateWidgetAuthenticationRequest?.success;

      if (otcDelivered) {
        updateWidgetAuthState({
          hasGeneratedAndSentAccessCode: true,
        });
      }
    } catch (error) {
      toast({ status: 'error', title: 'Sorry', description: "We're having trouble completing your request" });
      console.error('Error looking up client: ', error);
      Sentry.captureException(error);
    } finally {
      updateWidgetAuthState({
        hasPerformedWidgetAuthRequest: true,
      });
    }
  }, [emailAddress, generateAuthRequest, isGeneratingAuthRequest, phoneNumber, toast, updateWidgetAuthState]);

  const onFieldChange = (value: string): void => {
    setPassword(value);
  };

  const submitPassword = async (): Promise<void> => {
    setNumOfAttempts(numOfAttempts + 1);

    try {
      const result = await verifyWidgetAuthenticationRequest({
        variables: {
          data: {
            code: password,
          },
        },
      });

      if (
        result.data?.verifyWidgetAuthenticationRequest?.success &&
        result.data?.verifyWidgetAuthenticationRequest?.token
      ) {
        // Decode and store token
        setAuthToken(result.data.verifyWidgetAuthenticationRequest.token);
        const decodedToken = decodeAuthToken(result.data.verifyWidgetAuthenticationRequest.token);
        if (decodedToken && typeof decodedToken !== 'string') {
          updateWidgetState({ clinicPetParent: decodedToken.data.clinicPetParent });

          // If user already exists, skip create account step and navigate to appt details step
          if (canCreateClientPatient) {
            goToStep('AppointmentDetails');
          } else {
            goToNextStep();
          }
        }
      }
    } catch (error) {
      setHasAuthCodeError(true);
      resetPassword();
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const resetPassword = (): void => {
    setPassword('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (firstPinInputRef.current as any)?.focus();
  };

  const submitCodeMessage = useMemo(
    (): string =>
      `Your code was sent to ${
        accessCodeTransferMethod === AccessCodeTransferMethod.Email
          ? 'an email address starting with:'
          : 'a phone number ending in:'
      } ${scrambleAccessCodeTransferAddress(accessCodeTransferAddress, accessCodeTransferMethod)}`,
    [accessCodeTransferAddress, accessCodeTransferMethod],
  );

  const handleResendCode = async (): Promise<void> => {
    if (accessCodeTransferAddress) {
      resetPassword();
      setResendLabel('Code Resent');
      await generateOneTimeCode();
    }
  };

  const handleSubmitOnEnter = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      submitPassword();
    }
  };

  useEffect(() => {
    generateOneTimeCode();
    // Disabling this so otc is only fetched once
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      textAlign="center"
      m="auto"
      px={6}
      onKeyDown={handleSubmitOnEnter}
    >
      {isGeneratingAuthRequest && <Spinner isFullScreen />}
      {numOfAttempts >= MAX_AUTH_SUBMISSION_ATTEMPTS ? (
        <Alert status="error" hideCloseButton={true} hideIcon={true}>
          <Flex flexDir="column" align="center">
            <Icon variant="danger" name="warningSign" size="2xl" style={{ mb: 1 }} />
            <Text fontWeight="semibold">Maximum number of attempts reached.</Text>
            <Text>Too many login attempts. Please try again later.</Text>
          </Flex>
        </Alert>
      ) : (
        <>
          <Text mb={2}>{submitCodeMessage}</Text>

          {hasAuthCodeError && (
            <Alert status="error" mb={4} hideCloseButton={true} description="Invalid code submitted. Try again." />
          )}

          <HStack justify="space-between" mb={12}>
            <PinInput otp={true} autoFocus={true} onChange={onFieldChange} value={password}>
              <PinInputField ref={firstPinInputRef} />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>

          <Flex gap={4} flexDir="column" align="center">
            <Button onClick={submitPassword} isLoading={isVerifyingRequest}>
              Submit Code
            </Button>

            <Link onClick={handleResendCode}>Resend Code</Link>

            <Text>{resendLabel}</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default OneTimePassword;
