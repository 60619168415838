import { Flex } from '@chakra-ui/react';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { theme } from '@televet/kibble-ui/build/theme';
import { PoweredByStripeIcon } from 'assets/icons';
import React from 'react';

type FontSize = 'sm' | 'md';

interface IPoweredByStripeProps {
  themeColor?: string;
  size?: FontSize;
}


const PoweredByStripe = ({ themeColor = theme.colors.whiteAlpha[900], size = 'md' }: IPoweredByStripeProps): JSX.Element => {
  return (
    <Flex alignItems="center">
      <Icon name="lock" size="md" />
      <Text color={themeColor} size={size} ml={1} flex={1}>
        <Text color={themeColor} fontWeight="semibold" size={size}>
          Guaranteed Safe &amp; Secure
        </Text>
      </Text>
      <PoweredByStripeIcon
        fill={themeColor}
        cursor="pointer"
        onClick={(): void =>
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: 'https://stripe.com',
          }).click()
        }
      />
    </Flex>
  );
};

export default PoweredByStripe;
