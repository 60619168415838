import React from 'react';
import { useRadio, FormLabel, UseRadioProps, HStack } from '@televet/kibble-ui/build/chakra';
import { Avatar } from '@televet/kibble-ui/build/components/Avatar';
import { Radio } from '@televet/kibble-ui/build/components/Radio';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { getSpeciesTypeIcon } from 'shared/utils/getSpeciesTypeIcon';
import { WidgetPetFragment } from 'shared/types/graphql';
import { Card } from '@televet/kibble-ui/build/components/Card';

interface PetRadioProps extends UseRadioProps {
  pet: WidgetPetFragment;
}

const PetRadio = ({ pet, ...rest }: PetRadioProps): JSX.Element => {
  const { state, getInputProps, htmlProps } = useRadio(rest);

  return (
    <Card isInteractive isActive={state.isChecked} w="100%" size="sm">
      <FormLabel {...htmlProps} cursor="pointer" m="0">
        <HStack align="center" spacing={3}>
          <Radio isChecked={state.isChecked} inputProps={{ ...getInputProps() }} style={{ margin: 0 }} value={pet.id} />
          <Avatar
            variant="outlineAccent"
            iconName={getSpeciesTypeIcon(pet?.species || '')}
            size="md"
            cursor="pointer"
          />
          <Text fontWeight="bold">{pet?.name}</Text>
        </HStack>
      </FormLabel>
    </Card>
  );
};

export default PetRadio;
