export enum WidgetRequestTypeEventPrefix {
  RequestAppointment = 'appointment',
  RequestRxRefill = 'rx_refill',
  AdvancedRequestRxRefill = 'adv_rx_refill',
  RequestGroomingAppoinment = 'grooming',
  RequestBoarding = 'boarding',
  TalkToStaff = 'talk_to_staff',
  RequestMedicalRecords = 'medical_records',
  RequestVirtualConsult = 'virtual_consult',
  InboundSms = 'inbound_sms',
  DirectBooking = 'direct_book',
  Teletriage = 'teletriage',
}
